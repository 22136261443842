import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import _ from "lodash"
import PropTypes from 'prop-types';
import { flavours } from "helpers/const"
import * as React from 'react';
/* eslint-enable no-unused-vars */


export default class MTableGroupRow extends React.Component {

  rotateIconStyle = isOpen => ({
    transform: isOpen ? 'rotate(90deg)' : 'none'
  });

  render() {
    const {
      columns,
      options,
      detailPanel,
      actions,
      groups,
      level,
      groupData,
      components,
      usedPods,
      loadUsedPods
    } = this.props
    const flavorColumns = _.intersection(flavours.concat("N/A"), _.map(columns, item => item.field));
    let colSpan = columns.filter(columnDef => !columnDef.hidden).length;
    options.selection && colSpan++;
    detailPanel && colSpan++;
    actions && actions.length > 0 && colSpan++;
    const column = groups[level];
    let detail;
    if (groupData.isExpanded) {
      // console.log({groupData}, this.props.path)
      if (groups.length > (level + 1)) { // Is there another group
        detail = groupData.groups.map((groupData, index) => (
          <components.GroupRow
            actions={actions}
            key={groupData.value || ("" + index)}
            columns={columns}
            components={components}
            detailPanel={detailPanel}
            getFieldValue={this.props.getFieldValue}
            groupData={groupData}
            groups={groups}
            icons={this.props.icons}
            level={level + 1}
            path={[...this.props.path, index]}
            onGroupExpandChanged={this.props.onGroupExpandChanged}
            onRowSelected={this.props.onRowSelected}
            onRowClick={this.props.onRowClick}
            onToggleDetailPanel={this.props.onToggleDetailPanel}
            onTreeExpandChanged={this.props.onTreeExpandChanged}
            onEditingCanceled={this.props.onEditingCanceled}
            onEditingApproved={this.props.onEditingApproved}
            options={options}
            hasAnyEditingRow={this.props.hasAnyEditingRow}
            isTreeData={this.props.isTreeData}
          />
        ));
      }
      else {
        detail = groupData.data.map((rowData, index) => {
          if (rowData.tableData.editing) {
            return (
              <components.EditRow
                columns={columns}
                components={components}
                data={rowData}
                icons={this.props.icons}
                path={[...this.props.path, index]}
                localization={this.props.localization}
                key={index}
                mode={rowData.tableData.editing}
                options={options}
                isTreeData={this.props.isTreeData}
                detailPanel={detailPanel}
                onEditingCanceled={this.props.onEditingCanceled}
                onEditingApproved={this.props.onEditingApproved}
                getFieldValue={this.props.getFieldValue}
              />
            );
          } else {
            return (
              <components.Row
                actions={this.props.actions}
                key={index}
                columns={columns}
                components={components}
                data={rowData}
                detailPanel={detailPanel}
                getFieldValue={this.props.getFieldValue}
                icons={this.props.icons}
                path={[...this.props.path, index]}
                onRowSelected={this.props.onRowSelected}
                onRowClick={this.props.onRowClick}
                onToggleDetailPanel={this.props.onToggleDetailPanel}
                options={options}
                isTreeData={this.props.isTreeData}
                onTreeExpandChanged={this.props.onTreeExpandChanged}
                onEditingCanceled={this.props.onEditingCanceled}
                onEditingApproved={this.props.onEditingApproved}
                hasAnyEditingRow={this.props.hasAnyEditingRow}
              />
            );
          }
        });
      }
    }

    const freeCells = [];
    for (let i = 0; i < level; i++) {
      freeCells.push(<TableCell padding="checkbox" key={i} />);
    }

    let value = groupData.value;
    if (column.lookup) {
      value = column.lookup[value];
    }

    let title = column.title;
    if (typeof options.groupTitle === "function") {
      title = options.groupTitle(groupData);
    } else if (typeof title !== "string") {
      title = React.cloneElement(title);
    }

    let separator = options.groupRowSeparator || ": ";
    // console.log("groupData, this.props.path",groupData, this.props.path)
    return (
      <>
        <TableRow>
          {freeCells}
          <components.Cell
            colSpan={5}
            padding="none"
            columnDef={column}
            value={value}
            icons={this.props.icons}
          >
            <IconButton
              style={{ transition: 'all ease 200ms', ...this.rotateIconStyle(groupData.isExpanded) }}
              onClick={(event) => {
                this.props.onGroupExpandChanged(this.props.path);
              }}
            >
              <this.props.icons.DetailPanel />
            </IconButton>
            <b>{title}{separator}</b>
          </components.Cell>

          <TableCell padding="checkbox" colSpan={colSpan - 7 - freeCells.length - flavorColumns.length} />
          {_.map(flavorColumns, (item, index) =>
            <TableCell
              padding="checkbox"
              colSpan={1}
              key={`cell-${index}`}
              style={{ textAlign: "center" }}
            >
              {_.reduce(groupData.data, (accumulator, item2) => {
                return accumulator + item2[item]
              }, 0)}
            </TableCell>
          )
          }
          {loadUsedPods ? <TableCell padding="checkbox" colSpan={1} style={{ textAlign: "center" }}>{usedPods || 0}</TableCell> : <TableCell padding="checkbox" colSpan={1} />}
          <TableCell padding="checkbox" colSpan={1} />
        </TableRow>
        {detail}
      </>
    );
  }
}

MTableGroupRow.defaultProps = {
  columns: [],
  groups: [],
  options: {},
  level: 0
};

MTableGroupRow.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  components: PropTypes.object,
  detailPanel: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.object)]),
  getFieldValue: PropTypes.func,
  groupData: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object),
  hasAnyEditingRow: PropTypes.bool,
  icons: PropTypes.object,
  isTreeData: PropTypes.bool.isRequired,
  level: PropTypes.number,
  localization: PropTypes.object,
  onGroupExpandChanged: PropTypes.func,
  onRowSelected: PropTypes.func,
  onRowClick: PropTypes.func,
  onToggleDetailPanel: PropTypes.func.isRequired,
  onTreeExpandChanged: PropTypes.func.isRequired,
  onEditingCanceled: PropTypes.func,
  onEditingApproved: PropTypes.func,
  options: PropTypes.object,
  path: PropTypes.arrayOf(PropTypes.number),
};