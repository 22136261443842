import { createMuiTheme } from "@material-ui/core";
import theme from "./theme";

const mergeOptions = options => {
  return createMuiTheme({
    ...options,
    typography: {
      "fontFamily": "Sanchez",
      useNextVariants: true
    }
  });
};

export default mergeOptions(theme);
