import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";




const PublicRoute = ({ component: Component, restricted, auth, ...rest }) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            localStorage.getItem('__fwip_token__') && restricted ?
                <Redirect to="/dashboard" />
                : <Component {...props} />
        )} />
    );
};

const PublicRouteWrapper = connect(
    (state) => ({auth: state.auth}),
    null
)(PublicRoute);
export default PublicRouteWrapper
