import axios from "axios";
import _ from "lodash";
import config from "helpers/config";
import { logOut, refreshAccessToken } from "../redux/actions/auth";
import store from "../redux/store";

axios.defaults.baseURL = config.apiHost;

export function setHeader(headers = {}) {
  // eslint-disable-next-line
  for (const key in headers) {
    if (headers.hasOwnProperty(key)) {
      axios.defaults.headers.common[key] = headers[key];
    }
  }
}

// Add a request interceptor
axios.interceptors.request.use(
  async config => {
    const refreshToken = localStorage.getItem("__fwip_refresh_token__");
    const expires_date = localStorage.getItem("__fwip_expires_date__");
    if (
      refreshToken &&
      config.url !== "/auth/refresh" &&
      new Date().getTime() + 120 * 1000 > parseInt(expires_date) // check if the toke is expired in 2 min, if yes refresh the token
    ) {
        await store.dispatch(refreshAccessToken(refreshToken));
    } 
    const token = localStorage.getItem("__fwip_token__");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if ((_.get(error, "response.status") === 400 && _.get(error, "config.url")==="/auth/refresh") || _.get(error, "response.status") === 403) { // if the refresh token is expired, log out
        store.dispatch(logOut());
    }
    return Promise.reject(error.response ? error.response.data : {});
  }
);

export default axios;