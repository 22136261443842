import React from "react";
import { SvgIcon } from "@material-ui/core";

export const PinIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 21">
      <path
        d="M8.02489 -1.52588e-05C3.75851 -1.52588e-05 0.286133 3.47236 0.286133 7.73874C0.286133 9.4274 0.82013 11.0322 1.82941 12.377L7.54681 20.0794C7.56079 20.0989 7.58316 20.1045 7.59993 20.1213C7.8851 20.4261 8.29888 20.3506 8.50297 20.0794C10.1497 17.8819 13.1692 13.7777 14.3294 12.2316C14.3294 12.2316 14.3294 12.2316 14.3294 12.2288L14.3378 12.2204C15.2716 10.9064 15.7664 9.35751 15.7664 7.73874C15.7636 3.47236 12.2913 -1.52588e-05 8.02489 -1.52588e-05Z"
        fill="#601CBD"
      />
    </SvgIcon>
  );
};
