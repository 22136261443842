import {
  SETTINGS_FETCH,
  SETTINGS_FETCH_FINISH,
  SETTINGS_FETCH_ERROR,
} from "../actions/ActionTypes";

const initialState = {
  settings: {
    fetching: true,
    data: [],
    error: null
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SETTINGS_FETCH:
      return {
        ...state,
        settings: {
          ...state.list,
          fetching: true
        }
      };
    case SETTINGS_FETCH_FINISH:
      return {
        ...state,
        settings: {
          ...state.settings,
          fetching: false,
          error: null,
          data: action.payload
        }
      };
    case SETTINGS_FETCH_ERROR:
      return {
        ...state,
        settings: {
          ...state.settings,
          fetching: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
};
