
import Dashboard from "screens/Dashboard"
import Analytics from "screens/Analytics"
import Setting from "screens/Setting"
const tabs = [{
    index: 0, path: "/dashboard", component: Dashboard
  },
  {
    index: 1, path: "/analytics", component: Analytics
  },
  {
    index: 2, path: "/maps", component: "Maps"
  },
  {
    index: 3, path: "/settings", component: Setting
  },
  {
    index: 4, path: "/analytics/:id", component: Analytics
  }
]

  export default tabs