import moment from "moment"

export const flavours = ["chocolate", "vanilla", "strawberry", "mango", "natural", "caramel", "white"]

export const flavoursColor = [
  { label: "chocolate", color: "#9c755f" },
  { label: "vanilla", color: "#e6e7df" },
  { label: "strawberry", color: "#eea4bd" },
  { label: "mango", color: "#eeee5e" },
  { label: "natural", color: "#81d1e8" },
  { label: "caramel", color: "#c8ab77" },
  { label: "white", color: "#fcf9f0" },
  { label: "N/A", color: "#7b8696" },
]

export const statuses = ["online", "action", "inactive", "offline"]

export const team = [{ label: "Great Britain (GB)", value: "GB" }, { label: "Germany (DE)", value: "DE" }]

export const offlineTimeCalculate = (time) => {
  let offlineTime = time > 0 ? Math.round(moment.duration(time, 'seconds').asHours()) : null
  let offlineTimeString = offlineTime ? `${offlineTime} ${offlineTime > 1 ? "hours" : "hour"}` : ''
  if (offlineTime > 24) {
    offlineTime = Math.round(moment.duration(time, 'seconds').asDays())
    offlineTimeString = `${offlineTime} ${offlineTime > 1 ? "days" : "day"}`
  }
  return offlineTimeString
}
