
import _ from "lodash"

export default values => {
  const errors = {};
  if (!values.dateNumber) {
    errors.dateNumber = "app.general.requiredField";
  }
  if (parseInt(values.dateNumber) - 1 < 0 ) {
    errors.dateNumber = "app.dashboard.error.minIs1";
  }
  if (parseInt(values.dateNumber) > 1000 ) {
    errors.dateNumber = "app.dashboard.error.maxIs1000";
  }
  return errors;
};
