import React from "react"
import { ResponsivePie } from '@nivo/pie'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsivePie = ({ data /* see data tab */, chartColor, onClick }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 80, right: 0, bottom: 0, left: 20 }}
        padAngle={0.7}
        width={250}
        height={260}
        onClick={onClick}
        cornerRadius={0}
        colors={chartColor}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#000"
        animate={true}
        enableRadialLabels={false}
        motionStiffness={90}
        motionDamping={15}
        onMouseEnter={(data, event) => {
            event.target.style.cursor = "pointer";
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}

        legends={[
            {
                anchor: 'down',
                direction: 'column',
                translateY: -80,
                translateX: 20,
                itemWidth: 80,
                itemHeight: 18,
                itemTextColor: '#000',
                symbolSize: 18,
                symbolShape: 'square',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
)

export default MyResponsivePie