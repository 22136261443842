import React from "react";
import { SvgIcon } from "@material-ui/core";

export const PhoneIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M19.2559 14.253C19.1599 13.586 18.6449 13.066 17.9819 12.911C15.4049 12.307 14.7589 10.823 14.6499 9.17698C14.1929 9.09198 13.3799 8.99998 11.9999 8.99998C10.6199 8.99998 9.80691 9.09198 9.34991 9.17698C9.24091 10.823 8.59491 12.307 6.01791 12.911C5.35491 13.067 4.83991 13.586 4.74391 14.253L4.24691 17.695C4.07191 18.907 4.96191 20 6.19991 20H17.7999C19.0369 20 19.9279 18.907 19.7529 17.695L19.2559 14.253ZM11.9999 17.492C10.6049 17.492 9.47391 16.372 9.47391 14.992C9.47391 13.612 10.6049 12.492 11.9999 12.492C13.3949 12.492 14.5259 13.612 14.5259 14.992C14.5259 16.372 13.3939 17.492 11.9999 17.492ZM21.9499 7.99998C21.9259 6.49998 18.1079 4.00098 11.9999 3.99998C5.89091 4.00098 2.07291 6.49998 2.04991 7.99998C2.02691 9.49998 2.07091 11.452 4.58491 11.127C7.52591 10.746 7.34491 9.71898 7.34491 8.25098C7.34491 7.22698 9.73691 6.97998 11.9999 6.97998C14.2629 6.97998 16.6539 7.22698 16.6549 8.25098C16.6549 9.71898 16.4739 10.746 19.4149 11.127C21.9279 11.452 21.9729 9.49998 21.9499 7.99998Z"
        fill="#601CBD"
      />
    </SvgIcon>
  );
};
