import React from "react";
import { FormControlLabel, Checkbox, FormHelperText } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export default ({ input, label, meta: { error, touched }, className }) => {
  const hasError = !!(touched && error);
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            className={className}
            checked={input.value ? true : false}
            onChange={input.onChange}
            color="primary"
          />
        }
        label={label}
      />
      {hasError && (
        <FormHelperText error>
          <FormattedMessage id={error} />
        </FormHelperText>
      )}
    </div>
  )
};
