import React, { useState, useEffect,useRef } from "react";
import _ from "lodash";
import { SmallCard, DateRangePicker } from "./components";
import { connect } from 'react-redux';
import { StatsChart, CustomBox } from "components";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormattedMessage, injectIntl } from "react-intl";
import DateFnsUtils from "@date-io/date-fns";
import { Grid, Input } from "@material-ui/core";
import s from "./Dashboard.scss";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { colors } from "themes/theme";
import { fetchUsers } from "redux/actions/users"
import ChartPanel from "./components/ChartPanel"
import { reduxForm, formValueSelector } from "redux-form";
import { statuses } from "helpers/const"
import validate from "./validate";

import {
  fetchSaleDevices,
  fetchSalePods,
  fetchSaleAgents,
  fetchSaleCustomers,
  fetchAllSaleDevices,
  fetchAllSalePods,
  fetchStatusData,
  fetchSalePodFlavors,
  fetchPodSaleTeams,
  fetchSaleTeams
} from "../../redux/actions/statistics"

const FORMNAME = "charts-form"

const selector = formValueSelector(FORMNAME)

const cardsData = ({ totalMachines = 0, totalFreezers = 0, totalRetailUnit = 0, totalNA = 0, totalPods = 0, UKTeam = {}, DETeam = {}, totalTeamData = {} }) => [
  {
    key: "devices-pods",
    title: "Devices & Pods",
    headerBackgroundColor: "#5a3b97",
    contentBackgroundColor: "#cdbee4",
    maxHeight: 400,
    textColor: "white",
    content: [
      {
        label: "Machine",
        totalData: totalMachines
      },
      {
        label: "Freezer",
        totalData: totalFreezers
      },
      totalRetailUnit === 0 ?
        {
          label: "N/A",
          totalData: totalNA
        } :
        {
          label: "Unit Retail",
          totalData: totalRetailUnit
        },
      {
        label: "Pods",
        totalData: totalPods
      }
    ]
  },
  {
    key: "fwip",
    title: "Sales Team fwip",
    headerBackgroundColor: colors.rouge,
    contentBackgroundColor: colors.rougeLight,
    textColor: "white",
    content: [
      {
        label: "Agents",
        totalData: totalTeamData.agents || 0
      },
      {
        label: "Customer",
        totalData: totalTeamData.customers || 0
      },
      {
        label: "Locations",
        totalData: totalTeamData.location || 0
      },
      {
        label: "Machine",
        totalData: totalTeamData.machineCount || 0
      },
      {
        label: "Freezer",
        totalData: totalTeamData.freezerCount || 0
      },
      {
        label: "Retail Unit",
        totalData: totalTeamData.retailUnitCount || 0
      }
    ]
  },
  {
    key: "gb",
    title: "Team UK",
    headerBackgroundColor: colors.pink,
    contentBackgroundColor: colors.pinkLight,
    textColor: "white",
    content: [
      {
        label: "Agents",
        totalData: UKTeam.agents || 0
      },
      {
        label: "Customer",
        totalData: UKTeam.customers || 0
      },
      {
        label: "Locations",
        totalData: UKTeam.location || 0
      },
      {
        label: "Machine",
        totalData: UKTeam.machineCount || 0
      },
      {
        label: "Freezer",
        totalData: UKTeam.freezerCount || 0
      },
      {
        label: "Retail Unit",
        totalData: UKTeam.retailUnitCount || 0
      }
    ]
  },
  {
    key: "de",
    title: "Team DE",
    headerBackgroundColor: "#f2776b",
    contentBackgroundColor: "#fbd6d2",
    textColor: "white",
    content: [
      {
        label: "Agents",
        totalData: DETeam.agents || 0
      },
      {
        label: "Customer",
        totalData: DETeam.customers || 0
      },
      {
        label: "Locations",
        totalData: DETeam.location || 0
      },
      {
        label: "Machine",
        totalData: DETeam.machineCount || 0
      },
      {
        label: "Freezer",
        totalData: DETeam.freezerCount || 0
      },
      {
        label: "Retail Unit",
        totalData: DETeam.retailUnitCount || 0
      }
    ]
  }
];

// const flavoursData = [
//   {
//     name: "natural-yogurt",
//     count: 200,
//     color: colors.monteCarlo,
//     image: "natural-yogurt.png"
//   },
//   {
//     name: "vanilla_icecream",
//     count: 320,
//     color: colors.pink,
//     image: "vanilla_icecream.png"
//   },
//   {
//     name: "strawberry-sorbet",
//     count: 1333,
//     color: colors.green,
//     image: "strawberry-sorbet.png"
//   },
//   {
//     name: "caramel-icecream",
//     count: 1200,
//     color: colors.rouge,
//     image: "caramel-icecream.png"
//   },
//   {
//     name: "natural-yogurt",
//     count: 2000,
//     color: colors.lavenderIndigo,
//     image: "chocolate-icecream.png"
//   },
//   {
//     name: "natural-yogurt",
//     count: 100,
//     color: colors.froly,
//     image: "vanilla_icecream2.png"
//   }
// ];


const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    flexDirection: "row"
  },
  chartBoxStyle: {
    maxWidth: 1300,
  },
  title: {
    marginRight: 10,
    fontSize: "1.2rem",
    marginTop: 2,
    fontWeight: 100
  },
  title2: {
    marginRight: 10,
    fontSize: "1.2rem",
    fontWeight: 100
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  headerWrapper: {
    margin: theme.spacing(1),

    display: "flex",
    justifyContent: "space-between"
  },
  underlineInput: {
    "&::before": {
      borderBottom: 0
    },
    "&:hover": {
      "&::before": {
        borderBottom: "0 !important"
      }
    }
  },
  inputRoot: {
    color: "white",
    fontSize: "1rem",
    marginTop: -2
  },
  downIcon: {
    color: "white"
  }
}));

function ChartHeader({
  title,
  agentValue,
  changeAgentValue,
  granularity,
  onChangeGranularity,
  agents,
  begin, end,
  onChangeDatePicker
}) {
  const classes = useStyles();


  return (
    <div className={classes.headerWrapper}>
      <Grid container>
        <Grid className={classes.title}>{`${title || ""} | `}</Grid>
        <Grid className={classes.formControl}>
          <FormControl >
            <CustomSelect value={granularity} onChange={onChangeGranularity} classes={classes}>
              <MenuItem value={"daily"}>
                <FormattedMessage id="app.dashboard.chartBox.perDay" />
              </MenuItem>
              <MenuItem value={"monthly"}>
                <FormattedMessage id="app.dashboard.chartBox.perMonth" />
              </MenuItem>
            </CustomSelect>
          </FormControl>
          <FormControl >
            <CustomSelect value={agentValue} onChange={changeAgentValue} classes={classes}>
              {_.map(agents, (item, index) => (
                <MenuItem key={`menuAgent-${index}`} value={item.id}>
                  {item.email}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateRangePicker
          value={[begin, end]}
          placeholder="Select a date range"
          format="dd.MM.yyyy"
          onChange={onChangeDatePicker}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

function FlavourHeader(props) {
  const { title, intl } = props;
  const classes = useStyles();
  const [end, setEnd] = useState(new Date());
  const [begin, setBegin] = useState(
    moment()
      .startOf("year")
      .toDate()
  );

  return (
    <div className={classes.headerWrapper}>
      <Grid className={classes.title}>{`${title}`}</Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateRangePicker
          value={[begin, end]}
          placeholder={intl.formatMessage({
            id: "app.dashboard.datePicker.placeHolder"
          })}
          format="dd.MM.yyyy"
          onChange={values => {
            setBegin(values.begin);
            setEnd(values.end);
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

// const FlavourHeaderWrapper = injectIntl(FlavourHeader);

const CustomSelect = ({ value, onChange, children, classes }) => (
  <Select
    value={value}
    classes={{ icon: classes.downIcon }}
    onChange={onChange}
    input={
      <Input
        classes={{
          underline: classes.underlineInput,
          input: classes.inputRoot
        }}
      />
    }
  >
    {children}
  </Select>
);

function TopHeader({
  title = "",
  podsValue = "podNumber",
  changePodsValue,
  granularity = "lastMonth",
  changeGranularity
}) {
  const classes = useStyles();

  return (
    <div className={classes.headerWrapper}>
      <FormControl className={classes.formControl}>
        <Grid className={classes.title2}>{`${title || ""} | `}</Grid>
        <CustomSelect
          value={podsValue}
          onChange={changePodsValue}
          classes={classes}
        >
          <MenuItem value={"podNumber"}>
            <FormattedMessage id="app.dashboard.topBox.pods" />
          </MenuItem>
          <MenuItem value={"deviceNumber"}>
            <FormattedMessage id="app.dashboard.topBox.devices" />
          </MenuItem>
        </CustomSelect>
      </FormControl>
      <CustomSelect value={granularity} onChange={changeGranularity} classes={classes}>
        <MenuItem value={"thisMonth"}>this Month</MenuItem>
        <MenuItem value={"lastMonth"}>last Month</MenuItem>
      </CustomSelect>
    </div>
  );
}

function ChartContent({ boxColor, data, keys, title, chartColor, userList, fetchSales }) {
  const [granularity, setGranularity] = useState("monthly");
  const [end, setEnd] = useState(new Date());
  const [begin, setBegin] = useState(
    moment()
      .startOf("year")
      .toDate()
  );
  const onChangeDatePicker = values => {
    setBegin(values.begin);
    setEnd(values.end);
    fetchSales({ from: values.begin, to: values.end })
  }
  const renderData = data[granularity];
  const agents = userList || [];
  const [agentValue, changeAgentValue] = useState(_.get(userList, ["0", "id"], ""));
  const classes = useStyles();

  useEffect(() => {
    if (!_.isEmpty(userList)) {
      changeAgentValue(_.get(userList, ["0", "id"], ""))
    }
  }, [userList])

  useEffect(() => {
    if (agentValue) {
      fetchSales({ user_id: agentValue })
    }
  }, [agentValue])

  const counter = _.reduce(
    renderData || [],
    function (sum, n) {
      return sum + parseInt(n[keys]);
    },
    0
  );
  const onChangeAgentValue = event => {
    changeAgentValue(event.target.value);
  };
  const onChangeGranularity = event => {
    setGranularity(event.target.value);
  };
  return (
    <CustomBox
      boxColor={boxColor}
      classNameCard={classes.chartBoxStyle}
      title={
        <ChartHeader
          title={title}
          changeAgentValue={onChangeAgentValue}
          agentValue={agentValue}
          granularity={granularity}
          onChangeGranularity={onChangeGranularity}
          agents={agents}
          onChangeDatePicker={onChangeDatePicker}
          begin={begin}
          end={end}
        />
      }
    >
      <Grid container className="content-wrapper">
        <Grid
          item
          xs={10}
          className="barchart-wrapper"
          style={{ borderRight: `3px ${boxColor} solid` }}
        >
          <StatsChart
            data={renderData || []}
            keys={keys}
            colors={chartColor}
            buttonLegend={'date'}
            leftLegend={keys}
          // hideXAxis={true}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className="counter-wrapper"
          style={{ color: `${chartColor}` }}
        >
          <Grid className="counter">{counter}</Grid>
          <Grid>{<FormattedMessage id="app.dashboard.chartBox.total" />}</Grid>
        </Grid>
      </Grid>
    </CustomBox>
  );
}
function TopContent({ boxColor, data, title, backgroundColor, fetchSales, dataKey }) {
  const [podsValue, changePodsValue] = useState("podNumber");
  const [granularity, setGranularity] = useState("lastMonth");
  const onChangeGranularity = event => {
    setGranularity(event.target.value);
  };
  const onChangePodsValue = event => {
    changePodsValue(event.target.value);
  };
  useEffect(() => {
    let from, to
    switch (granularity) {
      case "lastMonth":
        from = moment().subtract(1, 'months').startOf('month').toDate()
        to = moment().subtract(1, 'months').endOf('month').toDate()
        break;
      case "thisMonth":
        from = moment().startOf('month').toDate()
        to = moment().toDate()
        break;
      default:
        from = null
        to = null
    }
    fetchSales({ sortedBy: podsValue, from, to })
  }, [podsValue, granularity])

  return (
    <CustomBox
      boxColor={boxColor}
      contentColor={backgroundColor}
      title={
        <TopHeader
          title={title}
          podsValue={podsValue}
          changePodsValue={onChangePodsValue}
          granularity={granularity}
          changeGranularity={onChangeGranularity}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormattedMessage id="app.dashboard.topBox.name" />
        </Grid>
        <Grid item xs={3}>
          <FormattedMessage id="app.dashboard.topBox.devices" />
        </Grid>
        <Grid item xs={3}>
          <FormattedMessage id="app.dashboard.topBox.pods" />
        </Grid>
      </Grid>
      {_.map(data, (item, index) => (
        <Grid container spacing={2} key={`row-${index}`}>
          <Grid item xs={6}>
            {`${index + 1}. `}
            {_.get(item, dataKey)}
          </Grid>
          <Grid item xs={3}>
            {item.deviceNumber}
          </Grid>
          <Grid item xs={3}>
            {item.podNumber}
          </Grid>
        </Grid>
      ))}
    </CustomBox>
  );
}

function Dashboard(props) {

  const {
    intl,
    users,
    fetchSaleDevices,
    fetchSalePods,
    devicesData,
    podsData,
    agentList,
    customerList,
    fetchSaleAgents,
    fetchSaleCustomers,
    allDeviceData,
    allPodData,
    dateSelector,
    statusData,
    saleTeams,
    fetchStatusData,
    dateNumber,
    fetchPodSaleTeams,
    fetchSalePodFlavors,
    flavorChart,
    fetchUsers,
    fetchAllSaleDevices,
    fetchAllSalePods,
    fetchSaleTeams,
    teamData,
    history
  } = props;

  const [teamChartData, setTeamChartData] = React.useState([]);
  const [flavorChartData, setFlavorChartData] = React.useState([]);
  // const totalDevices = allDeviceData.total
  const totalMachines = allDeviceData.machineCount
  const totalFreezers = allDeviceData.freezerCount
  const totalRetailUnit = allDeviceData.retailUnitCount
  const totalNA = allDeviceData.naCount
  const totalPods = allPodData.total

  const [UKTeam] = _.filter(teamData, item => item.saleTeam === "Sales UK")
  const [DETeam] = _.filter(teamData, item => item.saleTeam === "Sales DE")
  let keys = _.filter(UKTeam ? Object.keys(UKTeam) : [], function (item) {
    return item !== 'saleTeam'
  })
  let totalTeamData = _.zipObject(keys, keys.map(key => _.sum(_.map(teamData, key))))

  useEffect(() => {
    fetchUsers()
    fetchAllSaleDevices()
    fetchAllSalePods()
    fetchStatusData()
    fetchSaleTeams()
  }, [])

  const convertedNumber = parseInt(dateNumber) -1 

  useEffect(() => {
    let from, to
    if (dateSelector && _.isNumber(convertedNumber) ) {
      switch (dateSelector) {
        case "day":
          
          from = moment().subtract(convertedNumber, 'days').startOf('day').toDate()
          to = moment().toDate()
          setFlavorChartData(_.map(_.get(flavorChart, "daily"), item => ({ date: item.date, ...item.flavor })))
          break;
        case "week":
          from = moment().subtract((convertedNumber+1)*7-1, 'days').startOf('day').toDate()
          to = moment().toDate()
          setFlavorChartData(_.map(_.get(flavorChart, "daily"), item => ({ date: item.date, ...item.flavor })))
          break;
        case "month":
          
          from = moment().subtract(convertedNumber, 'months').startOf('day').toDate()
          to = moment().toDate()
          setFlavorChartData(_.map(_.get(flavorChart, "monthly"), item => ({ date: item.date, ...item.flavor })))
          break;
        default:
          from = null
          to = null
      }
      fetchSalePodFlavors({ from, to })
    }
  }, [flavorChart, dateNumber, dateSelector])

  useEffect(() => {
    let from, to
    if (dateSelector && _.isNumber(convertedNumber)) {
      switch (dateSelector) {
        case "day":
          from = moment().subtract(convertedNumber, 'days').startOf('day').toDate()
          to = moment().toDate()
          setTeamChartData(_.map(_.get(saleTeams, "daily"), item => ({ date: item.date, ...item.teams })))
          break;
        case "week":
          from = moment().subtract((convertedNumber+1)*7-1, 'days').startOf('day').toDate()
          to = moment().toDate()
          setTeamChartData(_.map(_.get(saleTeams, "daily"), item => ({ date: item.date, ...item.teams })))
          break;
        case "month":
          from = moment().subtract(convertedNumber, 'months').startOf('day').toDate()
          to = moment().toDate()
          setTeamChartData(_.map(_.get(saleTeams, "monthly"), item => ({ date: item.date, ...item.teams })))
          break;
        default:
          from = null
          to = null
      }
      fetchPodSaleTeams({ from, to })
    }
  }, [saleTeams, dateNumber, dateSelector])

  const pieChartData = _.map(statuses, status => {
    const [tempData] = _.filter(statusData, item => item.machineStatus === status)
    return ({ value: parseInt(_.get(tempData, "count", 0)), label: status, id: status })
  })
  return (
    <Grid container className={s.root}>
      <Grid className="flex-row">
        {_.map(cardsData({ totalMachines, totalFreezers, totalNA, totalRetailUnit, totalPods, UKTeam, DETeam, totalTeamData }), obj => (
          <SmallCard key={obj.key} details={obj} />
        ))}
      </Grid>
      <ChartPanel pieChartData={pieChartData} podsChartData={teamChartData} flavorChartData={flavorChartData} history={history} />
      <Grid container className="flex-row" spacing={5}>
        <Grid item xs={12} md={6} style={{ paddingLeft: 0 }}>
          <TopContent
            boxColor={colors.lavenderIndigo}
            data={agentList}
            fetchSales={fetchSaleAgents}
            dataKey="agent"
            title={intl.formatMessage({
              id: "app.dashboard.topBox.agents.heading"
            })}
            backgroundColor={colors.lavenderIndigoLight}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ paddingRight: 0 }}>
          <TopContent
            boxColor={colors.froly}
            data={customerList}
            dataKey="customer"
            fetchSales={fetchSaleCustomers}
            title={intl.formatMessage({
              id: "app.dashboard.topBox.customer.heading"
            })}
            backgroundColor={colors.frolyLight}
          />
        </Grid>
      </Grid>
      <ChartContent
        boxColor={colors.monteCarlo}
        data={devicesData}
        userList={users}
        fetchSales={fetchSaleDevices}
        keys="devices"
        title={intl.formatMessage({
          id: "app.dashboard.chartBox.devices.heading"
        })}
        chartColor={colors.lavenderIndigo}
      />
      <ChartContent
        boxColor={colors.green}
        data={podsData}
        userList={users}
        fetchSales={fetchSalePods}
        keys="pods"
        title={intl.formatMessage({
          id: "app.dashboard.chartBox.pods.heading"
        })}
        chartColor={colors.froly}
      />
      {/* we don't need this now, but maybe later */}
      {/* <Grid container className="flex-row">
        <CustomBox
          boxColor={colors.monteCarlo}
          classNameContent="custom-box"
          title={
            <FlavourHeaderWrapper
              title={intl.formatMessage({
                id: "app.dashboard.chartBox.flavours.heading"
              })}
            />
          }
        >
          <Grid
            container
            className={classNames("flex-row", "no-padding")}
            spacing={0}
          >
            {_.map(flavoursData, (item, index) => (
              <Grid item xs={4} md={2} key={`flavour-${index}`}>
                <Grid
                  className="flavour-count"
                  style={{
                    borderRight:
                      index !== _.size(flavoursData) - 1
                        ? `2px ${colors.monteCarlo} solid`
                        : null,
                    color: item.color
                  }}
                >
                  {item.count}
                </Grid>
                <Grid>
                  <CardMedia
                    image={`images/${item.image}`}
                    title="image"
                    className="img-wrapper"
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CustomBox>
      </Grid> */}
    </Grid>
  );
}


let DashboardWrapper = reduxForm({ form: FORMNAME, enableReinitialize: true, validate })(Dashboard);

DashboardWrapper = connect(
  state => ({
    initialValues: {
      dateSelector: "month",
      dateNumber: 7,
    },
    dateSelector: selector(state, 'dateSelector'),
    dateNumber: selector(state, 'dateNumber'),
    users: state.users.list.dataTable,
    devicesData: state.statistics.deviceChart.dataTable,
    allDeviceData: state.statistics.allDeviceData.data,
    allPodData: state.statistics.allPodData.data,
    podsData: state.statistics.podChart.dataTable,
    agentList: state.statistics.agentList.dataTable,
    customerList: state.statistics.customerList.dataTable,
    statusData: state.statistics.statusData.dataTable,
    saleTeams: state.statistics.saleTeams.dataTable,
    flavorChart: state.statistics.flavorChart.dataTable,
    teamData: state.statistics.teamData.dataTable,
  }),
  dispatch => ({
    fetchUsers: () => dispatch(fetchUsers()),
    fetchSaleDevices: (param) => dispatch(fetchSaleDevices(param)),
    fetchAllSaleDevices: () => dispatch(fetchAllSaleDevices()),
    fetchAllSalePods: () => dispatch(fetchAllSalePods()),
    fetchSalePods: (param) => dispatch(fetchSalePods(param)),
    fetchSaleAgents: (param) => dispatch(fetchSaleAgents(param)),
    fetchSaleCustomers: (param) => dispatch(fetchSaleCustomers(param)),
    fetchStatusData: (param) => dispatch(fetchStatusData(param)),
    fetchSalePodFlavors: (param) => dispatch(fetchSalePodFlavors(param)),
    fetchPodSaleTeams: (param) => dispatch(fetchPodSaleTeams(param)),
    fetchSaleTeams: (param) => dispatch(fetchSaleTeams(param)),
  })
)(injectIntl(DashboardWrapper));

export default DashboardWrapper;
