import React from 'react';
import _ from "lodash"
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import { Grid, Typography, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import { CustomTextField } from "components"
import * as authActions from "redux/actions/auth";
import validate from "./loginValidate";

const FORM_NAME = "login-form"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(images/background.png)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: "100vh"
  },
  loginForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    maxHeight: 800
  },
  img: {
    minHeight: 120,
    minWidth: 120,
    marginBottom: 36
  },
  imgContainer: {
    height: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center"
  },
  body: {
    width: 300
  },
  mrgBt20: {
    color: theme.colors.monteCarlo,
    fontSize: 20,
  },
  underlineInput: {
    "&::before": {
      borderBottom: `1px ${theme.colors.monteCarlo} solid`
    },
    "&:hover": {
      "&::before": {
        borderBottom: "0 !important"
      }
    }
  },
  loginBtn: {
    color: "#E6F4EA",
    height: 50
  },
  footer: {
    height: 50,
    alignSelf: "flex-end",
    textAlign: "center"
  },
  errorMsg: {
    color: "red",
    marginBottom: 20
  }
}));



function LoginForm(props) {
  const classes = useStyles();
  const handleSubmit = e => {
    e.preventDefault();
    const { email = "", password = "" } = _.get(props, `formValues.${FORM_NAME}.values`, {})
    props.onLogin({ username: email, password });
  };
  const { auth } = props;
  const authError = _.get(auth, "error")
  return (
    <Grid container className={classes.root}>
      <Grid item md={9} xs={12} className={classes.loginForm}>
        <CardMedia
          image={"images/fwip_logo.png"}
          title="image"
          className={classes.img}
        />
        <Grid className={classes.body}>
          <form onSubmit={handleSubmit}>
            <Grid container direction="column">
              <Field
                name="email"
                component={CustomTextField}
                label={<FormattedMessage id="app.general.email" />}
                labelClasses={{ root: classes.mrgBt20 }}
                wrapperStyle={{ marginBottom: 23 }}
                inputClasses={{ underline: classes.underlineInput }}
              />
              <Field
                name="password"
                component={CustomTextField}
                label={<FormattedMessage id="app.general.password" />}
                type="password"
                wrapperStyle={{ marginBottom: 23 }}
                labelClasses={{ root: classes.mrgBt20 }}
                inputClasses={{ underline: classes.underlineInput }}
              />
              {authError && (
                <div >
                  <Typography className={classes.errorMsg}>
                    <FormattedMessage id="app.login.errors.loginFailed" />
                  </Typography>
                </div>
              )}
              <Button
                variant="contained"
                className={classes.loginBtn}
                type="submit"
              >
                <Typography variant="button">
                  <FormattedMessage id="app.login.buttonLabel.login" />
                </Typography>
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid item md={3} xs={false} ></Grid>
      <Grid item md={12} xs={12} className={classes.footer}>
        <Typography >
          Copyright © 2020 FWIP
            </Typography>

      </Grid>
    </Grid >
  );
}


export default connect(
  state => ({ formValues: state.form, auth: state.auth, }),
  (dispatch) => {
    return {
      onLogin: data => {
        dispatch(authActions.login(data));
      }
    };
  }
)(reduxForm({
  form: FORM_NAME,
  validate
})(LoginForm));
