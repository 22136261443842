import {
  MACHINES_FETCH,
  MACHINES_FETCH_FINISH,
  MACHINES_FETCH_ERROR,
  MACHINE_FETCH,
  MACHINE_FETCH_FINISH,
  MACHINE_FETCH_ERROR,
  COMMENTS_FETCH,
  COMMENTS_FETCH_FINISH,
  COMMENTS_FETCH_ERROR,
  MACHINE_UPDATE,
  MACHINE_UPDATE_SUCCESS,
  MACHINE_UPDATE_ERROR,
  RESET_MACHINE_DATA
} from "../actions/ActionTypes";
import _ from "lodash"

const initialState = {
  list: {
    param: {},
    fetching: false,
    dataTable: [],
    error: null
  },
  machineData: {
    fetching: true,
    data: [],
    error: null
  },
  comments: {
    fetching: true,
    data: [],
    error: null
  },
  updateMachine: {
    success: false,
    error: null
  },
  filterParams: {},
  firstLoad: true
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case MACHINES_FETCH:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true
        }
      };
    case MACHINES_FETCH_FINISH:
      let param = {}
      let firstLoad = state.firstLoad
      if (state.firstLoad || (!state.firstLoad && _.get(action.payload.data, "paging.page") !== _.get(state.list.dataTable, "paging.page"))) {
        const dataTable = action.payload.data
        param = {
          deviceSNList: _.map(_.uniqBy(dataTable, "serial"), item => item.serial),
          statusList: _.map(_.uniqBy(dataTable, "machineStatus"), item => {
            switch (item.machineStatus) {
              case 'online':
                return { label: "Online", value: item.machineStatus }
              case 'action':
                return { label: "Action Required", value: item.machineStatus }
              case 'offline':
                return { label: "Offline", value: item.machineStatus }
              case 'inactive':
              default:
                return { label: "Inactive", value: "inactive" }
            }
          }),
          customerList: _.map(_.uniqBy(dataTable, "customerName"), item => ({ label: _.get(item, "customerName"), value: _.replace(_.get(item, "customerName"), /[.'*+?^${}|[\]\\]/g, '') })),
          userList: _.map(_.uniqBy(dataTable, "user"), item => ({ label: item.user, value: _.replace(item.user, /[.]/g, ' ') })),
          teamList: _.compact(_.map(_.uniqBy(dataTable, "saleTeam"), item => {
            if (_.get(item, "saleTeam")) return { label: _.get(item, "saleTeam"), value: _.get(item, "saleTeam") }
            return null
          })),
          locationList: _.map(_.uniqBy(dataTable, "shippingAddressCity"), item => ({ 
            label: `${_.get(item, "shippingAddressCity")}, ${_.get(item, "shippingAddressCountry")}`, 
            value: `${_.get(item, "shippingAddressCity")}|${_.get(item, "shippingAddressCountry")}` }))
        }
        firstLoad = false
      }
      return {
        ...state,
        list: {
          ...state.list,
          param: action.payload.param,
          fetching: false,
          error: null,
          pagination: action.payload.pagination,
          dataTable: action.payload.data
        },
        firstLoad: firstLoad,
        filterParams: {
          ...state.filterParams,
          ...param
        },
      };
    case MACHINES_FETCH_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          error: action.payload
        }
      };
    case MACHINE_FETCH:
      return {
        ...state,
        machineData: {
          ...state.machineData,
          fetching: true
        }
      };
    case MACHINE_FETCH_FINISH:
      return {
        ...state,
        machineData: {
          ...state.machineData,
          data: action.payload,
          fetching: false,
          error: null
        },
      };
    case MACHINE_FETCH_ERROR:
      return {
        ...state,
        machineData: {
          ...state.machineData,
          fetching: false,
          error: action.payload
        }
      };
    case MACHINE_UPDATE:
      return {
        ...state,
        updateMachine: {
          ...state.updateMachine,
          error: null,
          success: false
        }
      };
    case MACHINE_UPDATE_SUCCESS:
      return {
        ...state,
        updateMachine: {
          ...state.updateMachine,
          error: null,
          success: true
        }
      };
    case MACHINE_UPDATE_ERROR:
      return {
        ...state,
        updateMachine: {
          ...state.updateMachine,
          error: action.payload,
          success: false
        }
      };
    case COMMENTS_FETCH:
      return {
        ...state,
        comments: {
          ...state.comments,
          fetching: true
        }
      };
    case COMMENTS_FETCH_FINISH:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: action.payload,
          fetching: false,
          error: null
        },
      };
    case COMMENTS_FETCH_ERROR:
      return {
        ...state,
        comments: {
          ...state.comments,
          fetching: false,
          error: action.payload
        }
      };
      case RESET_MACHINE_DATA:
        return {
           ...state,
           machineData: initialState.machineData
        }
    default:
      return state;
  }
};
