import React from "react";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";

const CustomSelect = ({
  selectWrapper,
  customInput,
  classes,
  input,
  meta,
  selectedValueOutside,
  FormHelperTextProps,
  label,
  children,
  fullWidth,
  displayEmpty,
  hiddenError,
  customPlaceHolder,
  placeholderStyle,
  ...custom
}) => {
  return (
    <FormControl className={selectWrapper}>
      <Select
        {...input}
        {...custom}
        displayEmpty
        value={_.get(input, "value")}
        classes={classes}
        input={
          _.get(input, "value") ? (
            customInput
          ) : (
            <Input
              classes={
                 placeholderStyle
              }
            />
          )
        }
      >
        <MenuItem value="">
          <em>{customPlaceHolder}</em>
        </MenuItem>
        {children}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
