import React, { useEffect } from "react"
import _ from "lodash"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from "redux-form";
import DetailPage from "./components/DetailPage"
import AnalyticsTable from "./components/AnalyticsTable"
import { fetchMachines, fetchMachine } from "../../redux/actions/machines"
import queryString from 'query-string';
import { statuses } from "helpers/const"

const FORMNAME = "analytic-form"
const selector = formValueSelector(FORMNAME)


const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    flexDirection: "column",
    alignItems: "center",
  }
}));

function AnalyticTab(props) {
  const {
    searchFilter,
    filterFields,
    changeFieldValue,
    fetchMachines,
    machines,
    fetchStatusData,
    fetchMachine,
    history,
    match,
    isFetchingMachines,
    location
  } = props
  const classes = useStyles();

  const [openDetail, setOpenDetail] = React.useState(false);
  const [machineData, setMachineData] = React.useState({});
  const [loadUsedPods, setLoadUsedPods] = React.useState(false);
  const onDisablePodCount = () => {
    setLoadUsedPods(!loadUsedPods)
  }
  const onClickRowTable = (data) => {
    setMachineData(data)
    history.push(`analytics/${data.serial}`)
    setOpenDetail(true)
  }

  const pathName = _.get(match, "path")
  useEffect(() => {
    if (pathName !== "/analytics/:id") {
      setOpenDetail(false)
    }
    if (pathName === "/analytics/:id" && !openDetail) {
      history.push(`/analytics`)
    }
  }, [pathName])

  useEffect(() => {
    let params = queryString.parse(_.get(location, "search"))
    if (!_.isEmpty(params) && _.includes(statuses, _.get(params, "status"))) {
      changeFieldValue(`filter.status.${_.get(params, "status")}`, true)
    } else {
      fetchMachines({
        page: 1,
        pageSize: 200,
        sortedBy: 'serial',
        sortedDesc: false,
      }, true)
    }
  }, [location])

  const onCloseDetail = () => {
    history.push(`/analytics`)
    setLoadUsedPods(false)
    setOpenDetail(false)
  }

  const paramArr = []
  useEffect(() => {
    if (filterFields) {
      _.forEach(filterFields && Object.keys(filterFields), function (filterField) {
        const chipArr = []
        const param = `${filterField}_in`
        _.forEach(filterField && Object.keys(filterFields[filterField]), function (key) {
          if (_.get(filterFields, `${filterField}.${key}`)) {
            chipArr.push(_.replace(key, "SN__", ""))
          }
        })
        paramArr.push({ [param]: chipArr })
      })
      const endParam = !_.isEmpty(paramArr) ? paramArr.map(item => ({ [Object.keys(item)]: `[${_.map(Object.values(item), item => item)}]` })) : {}
      fetchMachines(Object.assign({}, ...endParam, { page: 1 }))
    }

  }, [filterFields])


  return (
    <Grid container className={classes.root}>

      <div style={{ display: !openDetail ? "block" : "none", width: "100%", maxWidth: 1400 }}>
        <AnalyticsTable
          data={machines}
          searchFilter={searchFilter}
          fetchStatusData={fetchStatusData}
          onClickRowTable={onClickRowTable}
          filterFields={filterFields}
          isFetchingMachines={isFetchingMachines}
          fetchMachines={fetchMachines}
          changeFieldValue={changeFieldValue}
          onDisablePodCount={onDisablePodCount}
          loadUsedPods={loadUsedPods}
        /> </div>
      {openDetail && <div style={{ display: !openDetail ? "none" : "block", width: "100%" }}>
        <DetailPage
          onCloseDetail={onCloseDetail}
          fetchMachine={fetchMachine}
          machineData={machineData}
        />
      </div>
      }

    </Grid>
  );
}
let AnalyticTabWrapper = reduxForm({ form: FORMNAME, enableReinitialize: true })(AnalyticTab);
AnalyticTabWrapper = connect(
  state => ({
    searchFilter: selector(state, 'searchFilter'),
    filterFields: selector(state, 'filter'),
    machines: state.machines.list.dataTable,
    isFetchingMachines: state.machines.list.fetching,
    machineData: state.machines.machineData.data
  }),
  dispatch => ({
    changeFieldValue: (field, value) => dispatch(change(FORMNAME, field, value)),
    fetchMachines: (param, resetParam) => dispatch(fetchMachines(param, resetParam)),
    fetchMachine: (sn) => dispatch(fetchMachine(sn))
  })
)(AnalyticTabWrapper);
export default AnalyticTabWrapper