import {
  SETTINGS_FETCH,
  SETTINGS_FETCH_FINISH,
  SETTINGS_FETCH_ERROR,
} from "./ActionTypes";
import ApiClient from "helpers/ApiClient";

export const fetchSettings = () => {
  return async (dispatch) => {
    dispatch({
      type: SETTINGS_FETCH
    });
    try {
      const { data } = await ApiClient.get("/users/me");
      return dispatch({
        type: SETTINGS_FETCH_FINISH,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: SETTINGS_FETCH_ERROR,
        payload: error
      });
    }
  };
};
