import React from 'react';


export default function GenericNotFound() {

  return (
    <div >
      Not found
    </div>
  );
}