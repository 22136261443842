import React from 'react';
import { useSelector } from "react-redux";
import _ from "lodash"
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { CustomCheckBox } from "components"
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ComputerIcon from '@material-ui/icons/Computer';
import { Field, FormSection } from "redux-form";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faUser, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FilterSection from "./FilterSection"

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
    panelRoot: {
        maxWidth: 400,
        minWidth: 300
    },
    panelContent: {
        alignItems: "center",

    },
    panelDetail: {
        flexDirection: "column",
        maxHeight: 200,
        overflowX: "auto"
    },
    statusIcon: {
        color: theme.colors.green,
    },
    icon: {
        color: "#6ec287",
        fontSize: "1rem",
        marginLeft: -10,
        marginRight: 10
    },
    deviceIcon: {
        color: theme.colors.pink,
    }
    ,
    teamIcon: {
        color: theme.colors.froly,
    },
    teamIceCream: {
        color: theme.colors.monteCarloLight,
    },
    locationIcon: {
        color: theme.colors.monteCarlo,
    },
    customerIcon: {
        color: theme.colors.rouge,
    },
    agentIcon: {
        color: theme.colors.lavenderIndigo,
    },
    filterSection: {
        backgroundColor: "white",
        paddingRight: "5px !important",
        height: 48
    },
    inputStyle: {
        width: "100%",
        justifyContent: "flex-end"
    },
    inputFilterStyle: {
        marginRight: 16,
    },
    underlineInput: {
        "&::before": {
            borderBottom: 0
        },
        "&::after": {
            borderBottom: 0
        },
        "&:hover": {
            "&::before": {
                borderBottom: "0 !important"
            }
        }
    }
}));


const ExpandedPanel = ({ children, defaultExpanded, classes, valueName, title, icon, expanded, handleChange }) => {

    return <ExpansionPanel expanded={expanded === title} onChange={handleChange(title)}>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ content: classes.panelContent }}
        >
            {icon}
            <Typography >{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.panelDetail }}>
            <FormSection name={valueName}>
                <Grid container direction={"column"}>
                    {children}
                </Grid>
            </FormSection>
        </ExpansionPanelDetails>
    </ExpansionPanel>
}

export default function FilterPopover(props) {
    const { anchorEl, handleClose, searchFilter } = props
    const form = useSelector(state => state.machines.filterParams);
    const { deviceSNList, customerList, userList, teamList, locationList, statusList } = form

    let filteredStatus =_.uniqBy(statusList, "label")  
    let filteredDeviceSNList = _.map(deviceSNList, item => ({ label: item, value: `SN__${item}` })) //tricky: to avoid render many number in redux, will remove the prefix later 
    let filteredLocationList = locationList
    let filteredCustomerList = customerList
    let filteredUserList = userList
    let filteredTeam = teamList
    if (searchFilter) {
        const lowerCaseSearchFilter = searchFilter.toLowerCase()
        filteredStatus = _.filter(filteredStatus, item => _.includes(_.get(item, "label", '').toLowerCase(), lowerCaseSearchFilter))
        filteredDeviceSNList = _.filter(filteredDeviceSNList, item => _.includes(_.get(item, "label", '').toLowerCase(), lowerCaseSearchFilter))
        filteredLocationList = _.filter(locationList, item => _.includes(_.get(item, "label", '').toLowerCase(), lowerCaseSearchFilter))
        filteredCustomerList = _.filter(customerList, item => _.includes(_.get(item, "label", '').toLowerCase(), lowerCaseSearchFilter))
        filteredUserList = _.filter(userList, item => _.includes(_.get(item, "label", '').toLowerCase(), lowerCaseSearchFilter))
        filteredTeam = _.filter(teamList, item => _.includes(_.get(item, "label", '').toLowerCase(), lowerCaseSearchFilter))
    }

    const classes = useStyles();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [expanded, setExpanded] = React.useState(false);


    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const renderCheckBoxes = (arr, key) => _.map(arr, (item, index) =>
        <Field
            key={`${key}-${index}`}
            name={item.value}
            component={CustomCheckBox}
            label={item.label}
        />
    )

    return (
        <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 2 }}
            disablePortal={true}
            modifiers={{
                flip: {
                    enabled: false,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                }
            }}
        >
            <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.panelRoot}>
                    <Field
                        name={"searchFilter"}
                        component={FilterSection}
                        containerStyle={classes.filterSection}
                        inputStyle={classes.inputStyle}
                        inputClasses={{ underline: classes.underlineInput, root: classes.inputFilterStyle }}
                    />
                    <FormSection name={"filter"}>
                        <ExpandedPanel
                            defaultExpanded={true}
                            classes={classes}
                            title={"Status"}
                            valueName={"status"}
                            icon={<FiberManualRecordIcon className={classnames(classes.icon, classes.statusIcon)} />}
                            expanded={expanded}
                            handleChange={handleChange}
                        >
                            {renderCheckBoxes(filteredStatus, 'status')}
                        </ExpandedPanel>
                        <ExpandedPanel
                            defaultExpanded={true}
                            classes={classes}
                            title={"Device S/N"}
                            valueName={"deviceSN"}
                            icon={<ComputerIcon className={classnames(classes.icon, classes.deviceIcon)} />}
                            expanded={expanded}
                            handleChange={handleChange}
                        >
                            {renderCheckBoxes(filteredDeviceSNList, 'serial')}
                        </ExpandedPanel>
                        <ExpandedPanel
                            defaultExpanded={true}
                            classes={classes}
                            title={"Location"}
                            valueName={"location"}
                            icon={<LocationOnIcon className={classnames(classes.icon, classes.locationIcon)} />}
                            expanded={expanded}
                            handleChange={handleChange}
                        >
                            {renderCheckBoxes(filteredLocationList, 'location')}
                        </ExpandedPanel>
                        <ExpandedPanel
                            defaultExpanded={true}
                            classes={classes}
                            title={"Customer"}
                            valueName={"customer"}
                            icon={<FontAwesomeIcon icon={faUser} className={classnames(classes.icon, classes.customerIcon)} />}
                            expanded={expanded}
                            handleChange={handleChange}
                        >
                            {renderCheckBoxes(filteredCustomerList, 'customer')}
                        </ExpandedPanel>
                        <ExpandedPanel
                            defaultExpanded={true}
                            classes={classes}
                            title={"User"}
                            valueName={"user"}
                            icon={<FontAwesomeIcon icon={faUserSecret} className={classnames(classes.icon, classes.agentIcon)} />}
                            expanded={expanded}
                            handleChange={handleChange}
                        >
                            {renderCheckBoxes(filteredUserList, 'user')}
                        </ExpandedPanel>
                        <ExpandedPanel
                            defaultExpanded={true}
                            classes={classes}
                            title={"Team"}
                            valueName={"team"}
                            icon={<FontAwesomeIcon icon={faUsers} className={classnames(classes.icon, classes.teamIcon)} />}
                            expanded={expanded}
                            handleChange={handleChange}
                        >
                            {renderCheckBoxes(filteredTeam, 'team')}

                        </ExpandedPanel>
                    </FormSection>
                </div>
            </ClickAwayListener>
        </Popper>
    );
}