import {
  MACHINES_FETCH,
  MACHINES_FETCH_FINISH,
  MACHINES_FETCH_ERROR,
  MACHINE_FETCH,
  MACHINE_FETCH_FINISH,
  MACHINE_FETCH_ERROR,
  COMMENTS_FETCH,
  COMMENTS_FETCH_FINISH,
  COMMENTS_FETCH_ERROR,
  CREATE_COMMENT,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_ERROR,
  MACHINE_UPDATE,
  MACHINE_UPDATE_SUCCESS,
  MACHINE_UPDATE_ERROR,
  RESET_MACHINE_DATA
} from "./ActionTypes";
import ApiClient from "helpers/ApiClient";

export const fetchMachines = (params = {}, resetParam) => {
  return async (dispatch, getState) => {
    dispatch({
      type: MACHINES_FETCH
    });
    try {
      params = resetParam ? params : { ...getState().machines.list.param, ...params };
      const { data } = await ApiClient.get("/machines", { params });
      return dispatch({
        type: MACHINES_FETCH_FINISH,
        payload: {
          param: {
            ...params,
            page: params.page,
            pageSize: params.pageSize,
            sortedBy: params.sortedBy,
            sortedDesc: params.sortedDesc
          },
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: MACHINES_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchMachine = (sn) => {
  return async (dispatch) => {
    dispatch({
      type: MACHINE_FETCH
    });
    try {
      const { data } = await ApiClient.get(`/machines/${sn}`);
      return dispatch({
        type: MACHINE_FETCH_FINISH,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: MACHINE_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const updateMachine = ({ sn, payload }) => {
  return async (dispatch) => {
    dispatch({
      type: MACHINE_UPDATE
    });
    try {
      const { data } = await ApiClient.put(`/machines/${sn}`, payload);
      return dispatch({
        type: MACHINE_UPDATE_SUCCESS,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: MACHINE_UPDATE_ERROR,
        payload: error
      });
    }
  };
};


export const fetchComments = (id) => {
  return async (dispatch) => {
    dispatch({
      type: COMMENTS_FETCH
    });
    try {
      const { data } = await ApiClient.get(`/machines/${id}/comments`);
      return dispatch({
        type: COMMENTS_FETCH_FINISH,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: COMMENTS_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const createComment = payload => {
  return async dispatch => {
    dispatch({
      type: CREATE_COMMENT,
      payload: ""
    });
    try {
      const { data } = await ApiClient.post(`/machines/comment`, payload);
      return dispatch({
        type: CREATE_COMMENT_SUCCESS,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: CREATE_COMMENT_ERROR,
        payload: error
      });
    }
  };
};

export const resetMachineData = () => {
  return {
    type: RESET_MACHINE_DATA
  }
}

export const uploadLogo = (sn, payload) => ApiClient.post(`/machines/${sn}/logo`, payload)

export const uploadGallery = (sn, payload) => ApiClient.post(`/machines/${sn}/gallery`, payload)

export const uploadGalleryComment = (sn, comment) => ApiClient.post(`/machines/gallery/${sn}/comments`, comment)

export const deleteGallery = (sn, photoId) => ApiClient.delete(`/machines/${sn}/gallery/${photoId}`)