import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from "lodash"
import { useSelector } from "react-redux";
import { lighten, makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MaterialTable, { MTableHeader, MTableToolbar, MTableGroupbar, MTableBody } from 'material-table'
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { FormattedHTMLMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ClearIcon from '@material-ui/icons/Clear';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
// import Tooltip from '@material-ui/core/Tooltip';
import FilterPopover from "./FilterPopover"
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import moment from "moment"
import { offlineTimeCalculate, flavoursColor, flavours } from "helpers/const"
import TableGroupRow from "./TableGroupRow"


const countUsedPod = (data, field) => _.reduce(data, (usedPodTotal, m) => usedPodTotal + m[field], 0)
const totalPodCount = (groups, key) => _.reduce(groups, (accumulator, item) => {
  const tempCount = !_.isEmpty(item.data) ? countUsedPod(item.data, key) : totalPodCount(item.groups, key)
  return accumulator + tempCount
}, 0)
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // marginTop: 50
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    overflowX: "unset"
  },
  statusInfoWrapper: {
    maxWidth: 1300,
    margin: 20,
    // backgroundColor:"#83CFCC"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  firstRow: { backgroundColor: "#f6f6f6", paddingLeft: 20 },
  firstRowCell: { textAlign: "center" },

}));


const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
  filterWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-end",
    flexWrap: "wrap",
    paddingBottom: 10,
    paddingTop: 10
  },
  chip: {
    borderRadius: 3,
    backgroundColor: theme.colors.monteCarlo,
    color: "white",
    height: 28,
    marginLeft: 8,
    marginTop: 12
  }
}));


const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, chipArr, changeFieldValue, data, searchFilter } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dataTable = _.get(data, "data", [])
  const deviceSNList = _.map(_.uniqBy(dataTable, "serial"), item => item.serial)
  const locationList = _.map(_.uniqBy(data, "location"), item => item.location)
  const customerList = _.map(_.uniqBy(data, "customer"), item => item.customer)
  const userList = _.map(_.uniqBy(data, "user"), item => item.user)

  const handleFilterClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <FilterPopover
        handleClose={handleFilterClose}
        anchorEl={anchorEl}
        deviceSNList={deviceSNList}
        locationList={locationList}
        customerList={customerList}
        userList={userList}
        searchFilter={searchFilter}
      />
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Grid container>
          <Grid item xs={9}>
            {numSelected > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1">
                {numSelected} selected
              </Typography>
            ) : (
                <Grid className={classes.filterWrapper}>
                  <IconButton aria-label="filter" onClick={handleFilterClick} style={{ marginRight: 8 }}>
                    <FontAwesomeIcon icon={faFilter} size="sm" color="#000" />
                  </IconButton>
          Selected filter:
                  {_.map(chipArr, (item, index) => <Chip
                    key={`chip-${index}`}
                    label={`${Object.keys(item)}: ${_.replace(Object.values(item), "SN__", "")}`}
                    onDelete={() => { changeFieldValue(`filter.${Object.keys(item)}.${Object.values(item)}`, false) }}
                    className={classes.chip}
                  />)}
                </Grid>
              )}
          </Grid>
        </Grid>
      </Toolbar>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


export default function AnalyticTable(props) {
  const classes = useStyles();
  const {
    data,
    searchFilter,
    filterFields,
    changeFieldValue,
    fetchMachines,
    onClickRowTable,
    isFetchingMachines,
    loadUsedPods,
    onDisablePodCount
  } = props
  const [extendTable, setExtendTable] = React.useState(false);
  const [isFirstLoad, setFirstLoad] = React.useState(true);
  const [groupedFields, setGroupedFields] = React.useState([7]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);
  const [orderBy, setOrderBy] = React.useState('serial');
  const [order, setOrder] = React.useState('asc');
  const [expandIndex, setExpandIndex] = React.useState(null);
  const [collapse, setCollapse] = React.useState(false);
  const tableRef = useRef();
  // const handleRequestSort = (event, property) => {
  //   setParam({
  //     ...param,
  //     sortedBy: property,
  //     sortedDesc:
  //       param.sortedBy === property && !param.sortedDesc
  //   });
  // };
  const groupedData = _.get(tableRef, "current.dataManager.groupedData", [])
  const isOneLevelGroup = _.isEmpty(_.filter(groupedData, item => !_.isEmpty(item.groups)))
  const handleRequestSort = (event, property) => {
    setOrder(property);
    setOrderBy(_.get(headerCells[event], "field"));
  };

  function descendingComparator(a, b, orderBy) {
    if (_.get(b, orderBy) < _.get(a, orderBy)) {
      return -1;
    }
    if (_.get(b, orderBy) > _.get(a, orderBy)) {
      return 1;
    }
    return 0;
  }


  function descendingComparatorUsedPods(a, b) {
    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }


  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const groupSort = (array) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const dataSumA = !_.isEmpty(a[0].data) ? countUsedPod(a[0].data, "usedPods") : totalPodCount(a[0].groups, "usedPods")
      const dataSumB = !_.isEmpty(b[0].data) ? countUsedPod(b[0].data, "usedPods") : totalPodCount(b[0].groups, "usedPods")
      return order === 'desc' ? descendingComparatorUsedPods(dataSumA, dataSumB) : -descendingComparatorUsedPods(dataSumA, dataSumB)
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handelPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  // const handelPageChange = (event, newPage) => {
  //   setParam({ ...param, page: newPage + 1 });
  // };
  const onSearchChange = query => {
    const params = {
      serial: query,
      customer: query,
      location: query,
      user: query,
      team: query,
      status: query,
      page: 1
    };
    fetchMachines(params)
  };

  // const handleChangeRowsPerPage = event => {
  //   setParam({
  //     ...param,
  //     page: 1,
  //     pageSize: +event.target.value
  //   });
  // };
  const chipArr = []
  _.forEach(filterFields && Object.keys(filterFields), function (filterField) {
    _.forEach(filterField && Object.keys(filterFields[filterField]), function (key) {
      if (_.get(filterFields, `${filterField}.${key}`)) {
        chipArr.push({ [filterField]: key })
      }
    })
  })
  const dataTable = data

  const machinesParams =
    useSelector(state => state.machines.list.param) || {};

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevProps = usePrevious({ machinesParams });
  // const prevGroupedData = usePrevious(groupedData)

  useEffect(() => {
    if (!_.isEmpty(machinesParams) && !_.isEmpty(prevProps && prevProps.machinesParams) && prevProps.machinesParams !== machinesParams) {

      setFirstLoad(false)
    }
  }, [machinesParams])

  const onExtendTable = () => {
    setExtendTable(!extendTable)
  }
 

  const headerCells = headCellsFcn(onExtendTable, isFirstLoad, onDisablePodCount, loadUsedPods)

  useEffect(() => {
    if (loadUsedPods) {
      fetchMachines({ loadUsedPods: true })
    }

  }, [loadUsedPods]);

  if (loadUsedPods) {

    headerCells.splice(_.size(headerCells) - 1, 0, {
      field: 'usedPods',
      title: 'used pods',
      cellStyle: {
        textAlign: "center",
        width: 20,
      },
      headerStyle: {
        textAlign: "center",
        width: 20,
      }
    })
  }
  // const flavoursWithNA = flavours.concat("N/A");
  if (extendTable) {
    _.forEach(flavoursColor, item => headerCells.splice(_.size(headerCells) - 2, 0, {
      field: item.label, sorting: false,
      headerStyle: {
        textAlign: 'center'
      },
      title: <Tooltip title={item.label}>
        <div style={
          {
            backgroundColor: item.color,
            height: 20,
            width: 20,
            display: "inline-block",
            verticalAlign: "middle"
          }} />
      </Tooltip>
    }))
  }
  const totalDispenseCount = _.get(_.find(dataTable, item => _.get(item, "totalDispenseCount")), "totalDispenseCount") || 0
  const adaptedDataTable = _.map(dataTable, (row, index) => {
    let countFlavor = 0
    _.forEach(flavours, flavour => {
      row[flavour] = 0
      _.forEach(row.dispenses, item2 => {
        if (_.includes(_.get(item2, "flavor"), flavour)) {
          row[flavour] = row[flavour] + 1
          countFlavor++
        }
      })
    })
    row["N/A"] = _.size(row.dispenses) - countFlavor
    const location = _.get(row, "shippingAddressStreet") ? `${_.get(row, "shippingAddressStreet")}, ${_.get(row, "shippingAddressCity")}, ${_.get(row, "shippingAddressCountry")}` : "n/a"
    let offlineTimeString = offlineTimeCalculate(_.get(row, "offlineTime"))
    let statusIcon = null
    switch (row.machineStatus) {
      case "online":
        statusIcon = <FiberManualRecordIcon style={{ color: "#6ec287" }} />
        break
      case "action":
        statusIcon = <FiberManualRecordIcon style={{ color: "#ffa382" }} />
        break
      case "offline":
        statusIcon = <FiberManualRecordIcon style={{ color: "red" }} />
        break
      case "inactive":
        statusIcon = <ClearIcon style={{ color: "#ff3823" }} />
        break
      default:
        statusIcon = 'n/a'
        break
    }
    const flavorsData = {}
    _.forEach(flavours, item => flavorsData[item] = row[`${item}Count`] || 0)
    return ({
      machineStatus: { icon: statusIcon, value: row.machineStatus },
      offlineTime: { label: offlineTimeString, value: _.get(row, "offlineTime") },
      lastDispenses: row.lastDispenses ? moment(new Date(parseInt(_.get(row, "lastDispenses")))).format("DD/MM/YYYY") : null,
      serial: row.serial,
      customerPhone: row.customerPhone,
      logo: row.logo,
      sim: row.sim,
      language: row.language,
      positioning: row.positioning,
      location: location,
      city: _.get(row, "shippingAddressCity") || "n/a",
      customer: _.get(row, "customerName") || "n/a",
      industry: _.get(row, "mainIndustry") || "n/a",
      user: row.user || "n/a",
      team: _.get(row, "saleTeam") || "n/a",
      category: _.replace(row.category, "Equipment /", ""),
      usedPods: row.usedPods || 0,
      ...flavorsData,
      "N/A": row.NACount || 0,
      machineId: row.machineId
    });
  })

  const statusInfos = [
    { icon: <FiberManualRecordIcon style={{ color: "#ffa382" }} />, text: "machine offline for > 24h" },
    { icon: <FiberManualRecordIcon style={{ color: "red" }} />, text: "machine offline for > 72h" },
    { icon: <FiberManualRecordIcon style={{ color: "#6ec287" }} />, text: "machine online within the last 24h" },
    { icon: <ClearIcon style={{ color: "#ff3823" }} />, text: "machine offline for more than 7 days" }
  ]
  return (
    <>
      <Grid container className={classes.statusInfoWrapper} >
        {_.map(statusInfos, item =>
          <Grid container>
            <Grid item xs={1} style={{ textAlign: "center" }}>
              {item.icon}
            </Grid>
            <Grid item xs={11}>
              {item.text}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Paper className={classes.paper} style={{ maxWidth: extendTable ? 1500 : 1300 }}>
        <MaterialTable
          onSearchChange={props => {
            onSearchChange(props)
          }}
          onRowClick={(event, rowData) => {
            onClickRowTable(rowData)
          }}
          tableRef={tableRef}
          onOrderChange={handleRequestSort}
          components={{
            Container: props => {
              return <Paper style={{ width: "100%" }}>
                {props.children}
              </Paper>
            },
            Toolbar: props => {
              return <Grid container>
                <Grid item xs={9}>
                  <EnhancedTableToolbar
                    data={data}
                    searchFilter={searchFilter}
                    numSelected={_.size(_.get(props, "selectedRows", []))}
                    chipArr={chipArr}
                    changeFieldValue={changeFieldValue}
                  />
                </Grid>

                {_.size(_.get(props, "selectedRows", [])) > 0 ?
                  <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end", backgroundColor: "rgb(255, 226, 236)" }} />
                  :
                  <Grid item xs={3}>
                    <MTableToolbar {...props} />
                  </Grid>
                }
              </Grid>

            },
            Groupbar: props => {
              const groupedColumns = _.map(props.groupColumns, item => item.tableData.columnOrder)
              _.forEach(groupedFields, (item, index) => {
                headerCells[item].defaultGroupOrder = index
              })
              useEffect(() => {
                if (!_.isEqual(groupedColumns, groupedFields)) {
                  setGroupedFields(groupedColumns)
                  setFirstLoad(false)
                }
              }, [groupedColumns, groupedFields]);
              return <MTableGroupbar {...props} />
            },
            Body: props => {
              let groupedData = props.renderData
              if (isOneLevelGroup) {
                groupedData = groupSort(props.renderData)

                const groupExpanded = (data) => {
                  if (!_.isEmpty(data.groups)) {
                    _.forEach(data.groups, item2 => {
                      item2.isExpanded = false
                      groupExpanded(item2)
                    })
                  }
                }

                if (!_.isEmpty(expandIndex)) {
                  _.forEach(groupedData, item => {
                    item.isExpanded = false
                    groupExpanded(item)
                  })
                  if (!collapse) {
                    const loopFcn = (group, ind) => {
                      if (group && group[expandIndex[ind]] && _.isNumber(expandIndex[ind])) {
                        _.forEach(group, groupItem => {
                          groupItem.isExpanded = false
                        })
                        group[expandIndex[ind]].isExpanded = true
                        const subGroup = group[expandIndex[ind]].groups
                        if (!_.isEmpty(subGroup) && ind <= _.size(expandIndex) - 1) {
                          loopFcn(subGroup, ind + 1)
                        }
                      }
                    }
                    loopFcn(groupedData, 0)
                  } else {
                    setExpandIndex(null)
                  }
                }
              }

              return <MTableBody {...props} renderData={groupedData} />
            },
            GroupRow: props => {
              const data = props.groupData
              const totalEntriesCount = (groups) => _.reduce(groups, (accumulator, item) => {
                const tempCount = !_.isEmpty(item.data) ? _.size(item.data) : totalEntriesCount(item.groups)
                return accumulator + tempCount
              }, 0)
              return <TableGroupRow
                {...props}
                totalPodCount={totalPodCount}
                onGroupExpandChanged={
                  (e) => {
                    if (isOneLevelGroup) {

                      setExpandIndex(e)
                      if (_.isEqual(e, expandIndex)) {
                        setCollapse(true)
                      } else {
                        setCollapse(false)
                      }
                    }
                    props.onGroupExpandChanged(e)
                  }
                }
                loadUsedPods={loadUsedPods}
                usedPods={!_.isEmpty(data.data) ? countUsedPod(data.data, "usedPods") : totalPodCount(data.groups, "usedPods")}
                groupData={{
                  ...props.groupData,
                  value: `${data.value || 'not available'} (${!_.isEmpty(data.data) ? _.size(data.data) : totalEntriesCount(data.groups)})`
                }}
              />
            }
            ,
            Header: props => {
              const orderByIndex = _.findIndex(headerCells, item => item.field === orderBy);
              return <>
                <MTableHeader
                  {...props}
                  tableRef={tableRef}
                  orderDirection={order}
                  orderBy={orderByIndex}
                />
                <TableHead className={classes.firstRow}>
                  <TableRow>
                    <TableCell colSpan={11} style={{ paddingLeft: 20 }}>
                      {"Total amount"}
                    </TableCell>
                    {extendTable && <TableCell colSpan={8} />}
                    {loadUsedPods && <TableCell colSpan={1} className={classes.firstRowCell} >
                      {totalDispenseCount}
                    </TableCell>}
                    <TableCell colSpan={1} />
                  </TableRow>
                </TableHead>
              </>
            },
            Pagination: props => {
              return <TablePagination
                {...props}
                component="th"
                count={_.size(dataTable) || 0}
                page={page}
                onChangePage={handelPageChange}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            }
          }}
          columns={headerCells}
          isLoading={isFetchingMachines}
          options={{
            thirdSortClick: false,
            grouping: true,
            sorting: isOneLevelGroup,
            selection: false,
            showTitle: false,
            paging: _.isEmpty(groupedFields),
            loadingType: "linear",
            pageSize: 200,
            pageSizeOptions: [100, 200, 500],
            debounceInterval: 2000,
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: "transparent",
              zIndex: 1,
              width: "100%"
            },
            cellStyle: {
              padding: 8
            }
          }}
          data={!_.isEmpty(groupedFields) ? stableSort(adaptedDataTable, getComparator(order, orderBy)) : stableSort(adaptedDataTable, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        />
      </Paper>
    </>
  );
}

const headCellsFcn = (onExtendTable, isFirstLoad, onDisablePodCount, loadUsedPods) => [
  {
    field: 'machineStatus.value', title: 'Status', cellStyle: {
      padding: 12,
      width: 30
    },
    render: rowData => {
      return _.get(rowData, "machineStatus.icon") || rowData
    }
  },
  {
    field: 'offlineTime.value', title: <FormattedHTMLMessage id="app.analytic.table.offlineTime" />,
    render: rowData => {
      return _.get(rowData, "offlineTime.label")
    }
  },
  { field: 'lastDispenses', title: <FormattedHTMLMessage id="app.analytic.table.dispensed" /> },
  {
    field: 'serial', title: 'Device S/N', cellStyle: {
      minWidth: 120,
      fontSize: "inherit",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  {
    field: 'location', title: 'Location',
    cellStyle: {
      minWidth: 150
    }
  },
  {
    field: 'city', title: 'City',
    cellStyle: {
      minWidth: 80
    }
  },
  {
    field: 'industry', title: 'Industry'
  },
  {
    field: 'category', title: 'Type'
  },
  isFirstLoad ? { field: 'customer', title: 'Customer', defaultGroupOrder: 0 } : { field: 'customer', title: 'Customer' },
  {
    field: 'user', title: 'User', cellStyle: {
      minWidth: 120,
      fontSize: "inherit",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  { field: 'team', title: 'Team' },
  {
    field: 'usedPods2',
    title: <div style={{ display: "flex" }}><IconButton onClick={onExtendTable} disabled={!loadUsedPods}>
      <AspectRatioIcon /></IconButton><Switch
        checked={loadUsedPods}
        onChange={onDisablePodCount}
      /></div>,
    sorting: false,
    headerStyle: {
      textAlign: "right",
      paddingRight: 12,
      width: 30
    }
  }
];