import React from "react";
import _ from "lodash";
import { TextField, FormHelperText } from "@material-ui/core";
import { FormattedMessage } from "react-intl"

const CustomTextField = ({
  input,
  meta,
  FormHelperTextProps,
  label,
  wrapperStyle,
  labelClasses,
  hiddenError,
  textFieldStyle = {},
  textFieldErrorStyle = {},
  inputProps= {},
  InputProps,
  inputClasses,
  ...custom
}) => {
  const hasError = meta&&!!(meta.touched && meta.error);
  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "column", ...wrapperStyle }}>
        <TextField
          label={label}
          error={hasError}
          // classes={classes}
          {...input}
          {...custom}
          InputProps={{classes: inputClasses, ...InputProps}}
          InputLabelProps={{classes: labelClasses}}
          inputProps = {inputProps}
          style={hasError ? { marginBottom: 0, ...textFieldErrorStyle } : textFieldStyle}
        />
        {!hiddenError && hasError && (
          <FormHelperText {..._.assign({}, FormHelperTextProps)} error>
            <FormattedMessage id={meta.error} />
          </FormHelperText>
        )}
      </div>
    </React.Fragment>
  );
};
export default CustomTextField