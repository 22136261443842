export const colors = {
  transparent: "transparent",
  gray: "#90908B",
  lightgray: "#D8D8D0",
  monteCarloLight: "#83CFCC",
  monteCarlo: "#27b2ad",
  lavenderIndigo: "#5a3b97",
  lavenderIndigoLight: "#cdbee4",
  general: "#313840",
  white: "white",
  red: "red",
  green: "#6ec287",
  froly: "#f2776b",
  frolyLight: "#fbd6d2",
  rouge: "#973163",
  rougeLight: "#dec0cf",
  pinkLight: "#ecd9e9",
  pink: "#c383b7"
};

const theme = {
  palette: {
    primary: {
      light: "#83CFCC",
      main: "#83CFCC",
    }
  },
  colors: colors,
  overrides: {
    MuiTableCell: {
      root: {
        maxWidth: 100,
        paddingLeft: 2,
        paddingRight: 2
      },
      paddingCheckbox: {
        backgroundColor: "transparent !important",
        width: 20
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          margin: "2px 0"
        }
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          color: colors.monteCarlo
        }
      }
    },
    MuiTableRow: {
      root: {
      height: 50
      }
    },
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          color: colors.monteCarlo
        },
        "&$checked + $track": {
          backgroundColor: colors.monteCarloLight
        }
      }
    },
    MuiRadio: {
      colorPrimary: {
        "&$checked": {
          color: colors.monteCarlo
        }
      }
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#B9E3E1"
      }
    },
    MuiIconButton: {
      root: {
        padding: 4
      }
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          color: colors.monteCarlo
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        color: colors.gray,
        backgroundColor: colors.white,
        border: "1px solid #dadde9",
        maxWidth: 1000
      }
    },
    MuiTabs: {
      root: {
        backgroundColor: colors.monteCarloLight,
        height: 50
      },
      flexContainer: {
        justifyContent: "center",
        marginTop: -10
      },
      indicator: {
        height: 2,
        backgroundColor: colors.white
      }
    },
    MuiTab: {
      root: {
        textTransform: "none",
        opacity: "1 !important",
        minWidth: "200px !important",
        "&$selected": {
          "& span": {
            color: colors.white,
          }
        }
      },
      wrapper: {
        color: colors.monteCarlo,
        fontSize: 26,
        fontWeight: 600
      }
    },
    MuiTypography: {
      button: {
        fontWeight: "bold",
        textTransform: "none",
        fontSize: "1rem"
      },
      h5: {
        fontWeight: "bold"
      },
      h6: {
        fontSize: 16,
        color: colors.general
      },
      subtitle1: {
        color: colors.gray,
        fontWeight: "bold"
      },
      subtitle2: {
        color: colors.general,
        fontWeight: "bold",
        fontSize: 16
      },
      body1: {
        fontSize: 14,
        color: colors.general,
        textTransform: "none",
        height: "100%",
      },
      body2: {
        fontSize: 11,
        color: colors.gray
      }
    },
    MuiLink: {
      button: {
        color: colors.monteCarlo,
        fontSize: 14
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiTextField: {},
    MuiInputBase: {
      input: {
        color: colors.general
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: colors.monteCarlo,
        color: "white",
        borderRadius: 0,
        "&$disabled": {
          color: colors.gray
        },
        "&:hover": {
          backgroundColor: colors.monteCarloLight,
          color: colors.general
        }
      },

      outlined: {
        borderColor: colors.white,
        "&:hover": {
          borderColor: colors.monteCarloLight,
          backgroundColor: colors.monteCarloLight,
          color: colors.general
        }
      },
      text: {
        color: colors.black,
        backgroundColor: colors.transparent,
        textTransform: "none",
        "&:hover": {
          backgroundColor: colors.transparent
        }
      }
    },
    MuiSelect: {
      icon: {
        marginTop: -2,
        fontSize: "1.7rem",
      },
    }
  }
};

export default theme;
