import { parse } from "querystring";

export const EMAIL_VALIDATION_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const transformLocation = (location, distance) => {
  if (!location) return {};
  const { lat, long, ...rest } = location;
  const pos = {
    lat: lat,
    lng: long,
  };
  return { pos, ...rest, distance };
};

export const parseQuery = (search = "") => {
  if (search.startsWith("?")) search = search.slice(1);
  const queries = parse(search);
  return queries;
};

export const round = (num, fractionDigits = 2) => {
  if (typeof num !== "number") return num;
  return Number(num.toFixed(fractionDigits));
};
