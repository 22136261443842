import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  SET_TOKEN
} from "../actions/ActionTypes";
import { setHeader } from 'helpers/ApiClient';

let initialState = {
  token: {},
  error: null,
  changePassword: {
    fetching: false,
    success: false,
    error: null
  }
};

let authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN:
    case SET_TOKEN:
    case LOGIN_SUCCESS:
      if (action.payload) {
        localStorage.setItem('__fwip_token__', action.payload.access_token);
        localStorage.setItem('__fwip_refresh_token__', action.payload.refresh_token);
        const expires_date = new Date().getTime() + action.payload.expires_in*1000
        localStorage.setItem('__fwip_expires_date__', expires_date);
        setHeader({ Authorization: 'Bearer ' + action.payload.access_token });
        return {
          ...state,
          error: null,
          token: action.payload,
        };
      } else return state;
    case LOGIN_ERROR:
      return {
        ...state,
        access_token: null,
        error: action.payload
      };
    case LOGOUT:
      localStorage.removeItem("__fwip_token__");
      localStorage.removeItem('__fwip_refresh_token__');
      localStorage.removeItem('__fwip_expires_in__');
      setHeader({ Authorization: null });
      return {
        ...state,
        access_token: null
      };
    default:
      return state;
  }
};

export default authReducer;
