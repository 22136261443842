import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core";
import * as themes from "./themes";
import { Provider } from "react-intl-redux";
import { Home, Login, PublicMap } from "./containers";
import store from "./redux/store";
import PrivateRoute from "./containers/PrivateRoute";
import PublicRoute from "./containers/PublicRoute";
import GenericNotFound from "containers/GenericNotFound"
import { Route, Redirect } from 'react-router-dom';
import Helmet from "react-helmet";
import _ from "lodash"
import routes from "./routes"

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={themes["default"]}>
          <Helmet titleTemplate={"%s — fwip"} defaultTitle={"fwip"} />
          <Router>
            <Switch>
              <PublicRoute
                restricted={true}
                component={Login}
                path="/"
                exact
              />
              <PublicRoute
                restricted={true}
                component={PublicMap}
                path="/public-map"
                exact
              />
              {_.map(routes, (item, index) => <PrivateRoute component={Home} tabIndex={item.index} path={item.path} exact />)}
              <Route path="/404" component={GenericNotFound} />
              <Redirect to="/" />
            </Switch>
          </Router>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
