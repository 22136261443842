import {
  USERS_FETCH,
  USERS_FETCH_FINISH,
  USERS_FETCH_ERROR,
} from "./ActionTypes";
import ApiClient from "helpers/ApiClient";

export const fetchUsers = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: USERS_FETCH
    });
    try {
      params = { ...getState().users.list.param, ...params };
      const { data } = await ApiClient.get("/users", { params });
      return dispatch({
        type: USERS_FETCH_FINISH,
        payload: {
          param: {
            ...params,
            sortedBy: params.sortedBy,
            sortedDesc: params.sortedDesc
          },
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: USERS_FETCH_ERROR,
        payload: error
      });
    }
  };
};
