import {
  Button,
  Typography,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { InfoWindow as GGInfoWindow } from "@react-google-maps/api";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { PhoneIcon, PinIcon } from "components/Icons";
import cN from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import "./index.css";

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: 50,
    backgroundColor: "#69CBB5",
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  title: {
    fontFamily: "'Museo Sans 900'",
  },
  subtitle: {
    fontFamily: "'Museo Sans 500'",
  },
  body: {
    color: "#601cbd",
  },
  closeBtn: {
    backgroundColor: "#601cbd",
    color: theme.palette.common.white,
    position: "absolute",
    top: 0,
    right: 0,
    "&:hover": {
      backgroundColor: "#601cbd",
    },
  },
}));

const InfoWindow = ({
  windowPosition,
  onInfoWindowClose,
  windowTitle,
  distant,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <GGInfoWindow
      position={windowPosition}
      onCloseClick={onInfoWindowClose}
    >
      <Box maxWidth={250} pb={1} pt={3} position="relative">
        <IconButton className={classes.closeBtn} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Box mb={1}>
          <Typography variant="h5" className={classes.title}>
            {windowTitle.store_name}
          </Typography>
        </Box>
        <Typography variant="h6" className={classes.subtitle}>
          {windowTitle.address1},
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          {[windowTitle.address2, windowTitle.city, windowTitle.post_code]
            .filter(Boolean)
            .join(", ")}
        </Typography>

        <Box mt={1}>
          {windowTitle.phone_number && (
            <Box display="flex" alignItems="center">
              <Box clone mr={1}>
                <PhoneIcon />
              </Box>
              <Typography className={classes.subtitle} variant="h6">
                {windowTitle.phone_number}
              </Typography>
            </Box>
          )}
          {distant && (
            <Box display="flex" alignItems="center">
              <Box clone mr={1}>
                <PinIcon />
              </Box>
              <Typography className={classes.title} variant="h6">
                {`${distant} Miles away`}
              </Typography>
            </Box>
          )}
        </Box>

        <Box mt={2}>
          <Typography className={cN([classes.title, classes.body])}>
            Please check opening hours before visiting
          </Typography>
        </Box>

        <Box mt={4}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.btn}
            size="small"
            onClick={() => {
              window
                .open(
                  `https://www.google.com/maps/search/?api=1&query=${windowPosition.lat},${windowPosition.lng}`,
                  "_blank"
                )
                .focus();
            }}
          >
            <Typography variant="button">
              <FormattedMessage id="app.map.getDirection" />
            </Typography>
          </Button>
        </Box>
      </Box>
    </GGInfoWindow>
  );
};

export default InfoWindow;
