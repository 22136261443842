import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from "react-intl";
import ExitToApp from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from "react-redux";
import * as authActions from "redux/actions/auth";
import routes from "../routes"
import _ from "lodash"


const newRoutes = _.map(routes, (item, index) => index < 4 && item)

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ padding: 24, backgroundColor: "#E6F4EA", minHeight: "100vh" }}
      {...other}
    >
      {value === index && <Box style={{ paddingTop: 24 }} >{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    display: "flex",
    flexDirection: "row",
    height: 100,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  img: {
    minHeight: 80,
    minWidth: 80,
  },
  imgContainer: {
    height: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center"
  },
  logOut: {
    justifyContent: "flex-end",
    height: "100%",
    alignItems: "center",
    display: "flex",
    paddingRight: 20
  }
}));

function Home(props) {
  const { tabIndex = 0, match, history } = props
  const classes = useStyles();
  const [value, setValue] = React.useState(tabIndex);
  const pathName = _.get(match, "path")

  useEffect(() => {
    if (pathName !== "/analytics/:id") {
      const findIndex = _.find(routes, item => item.path === pathName)
      findIndex && setValue(findIndex.index);
    }
  }, [pathName])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const findIndex = _.find(newRoutes, item => item.index === newValue)
    history.push(findIndex.path)
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Grid item xs={1} className={classes.imgContainer}>
          <CardMedia
            image={"images/fwip_logo.png"}
            title="image"
            className={classes.img}
          />
        </Grid>
        <Grid item xs={10} >
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" >
            <Tab label={<FormattedMessage id="app.tab.title.dashboard" />} {...a11yProps(0)} disableRipple />
            <Tab label={<FormattedMessage id="app.tab.title.analytics" />} {...a11yProps(1)} disableRipple />
            <Tab label={<FormattedMessage id="app.tab.title.maps" />} {...a11yProps(2)} disableRipple />
            <Tab label={<FormattedMessage id="app.tab.title.settings" />} {...a11yProps(3)} disableRipple />
          </Tabs>
        </Grid>
        <Grid item xs={1} className={classes.logOut}>
          <Tooltip title="Log out">
            <IconButton aria-label="logout" onClick={props.onLogout}>
              <ExitToApp style={{ color: "white", fontSize: "2rem" }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </AppBar>
      {_.map(routes, (item, index) => {
        const Component = item.component
        return <TabPanel key={`tab-${index}`} value={value} index={item.index}>
          <Component {...props} />
        </TabPanel>
      })}
    </div>
  );
}

export default connect(
  null,
  (dispatch) => {
    return {
      onLogout: () => {
        dispatch(authActions.logOut());
      }
    };
  }
)(Home);