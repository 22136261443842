import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  IconButton
} from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { injectIntl } from "react-intl";
import _ from "lodash"
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import classnames from "classnames";

const styles = () => ({
  toolTableContainer: {
    paddingLeft: 20,
    paddingRight: 20
  },
  titleContainer: {
    display: "flex",
    alignSelf: "center"
  },
  box: {
    paddingLeft: 4
  },
  input: {
    minWidth: 200
  },
});

function FilterSection(props) {
  const { intl, classes, customFilter, containerStyle, inputStyle, inputClasses, input, onSearchChange } = props;

  if (customFilter) {
    return customFilter;
  }
  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      className={classnames(classes.toolTableContainer, containerStyle)}
    >
      <TextField
        classes={{ root: classes.input }}
        className={inputStyle}
        placeholder={intl.formatMessage({
          id: "app.search.title"
        })}
        onChange={event=> {
          if (_.isFunction(onSearchChange)) onSearchChange(event.target.value)
          if (_.isFunction(_.get(input, "onChange"))) input.onChange(event.target.value)
        }}
        {...input}
        InputProps={{
          classes: inputClasses,
          endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
            >
              {_.get(input, "value") ?
                <HighlightOff
                  onClick={() => {
                    if (_.isFunction(_.get(input, "onChange"))) input.onChange("")
                  }}
                />
                : <SearchIcon />}
            </IconButton>
          </InputAdornment>
        }}
      />
    </Grid>
  );
}

export default injectIntl(withStyles(styles)(FilterSection));
