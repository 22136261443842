import React from "react";
import { Marker as GGMarker } from "@react-google-maps/api";

const Marker = ({ position, onMarkerClick }) => {
  return position.map((item) => (
    <GGMarker
      onMouseOver={() => onMarkerClick(item)}
      onClick={() => onMarkerClick(item)}
      icon={{
        url: `${window.location.origin}/images/fwip-cone-map-icon.svg`,
      }}
      position={item.pos}
    ></GGMarker>
  ));
};

export default Marker;
