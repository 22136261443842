import React, { Component } from "react"
import { ResponsiveBar } from "@nivo/bar"
import _ from "lodash"

class Chart extends Component {
  render() {
    const { data, keys, buttonLegend, leftLegend, hideXAxis, colors, labelColor, groupMode="grouped" } = this.props
    return (
      <ResponsiveBar
        data={data}
        keys={_.isArray(keys) ? keys : [keys]}
        indexBy={buttonLegend}
        margin={{ top: 60, right: 60, bottom: 80, left: 60 }}
        padding={0.5}
        groupMode={groupMode}
        colors={colors}
        labelTextColor={labelColor || "#fff"}
        axisBottom={
          hideXAxis
            ? null
            : {
              tickSize: 5,
              tickPadding: 10,
              tickRotation: _.size(data) > 20 ?  90 : 0,
              legendOffset: 32
            }
        }
        axisLeft={{
          tickSize: 11,
          tickPadding: 2,
          tickRotation: 0,
          legend: leftLegend,
          legendPosition: "middle",
          legendOffset: -55
        }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
          {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 20,
              translateY: -30,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 10,
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemOpacity: 1
                      }
                  }
              ]
          }
      ]}
      />
    )
  }
}

export default Chart
