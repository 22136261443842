import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StatsPie from "components/StatsPie";
import { Grid } from "@material-ui/core";
import { StatsChart } from "components";
import { CustomSelect, CustomTextField } from "components";
import { Field } from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash"
import { flavours } from "helpers/const"

const useStyles = makeStyles(theme => ({
  panelRoot: {
    width: "100%",
    maxWidth: 1300,
    marginBottom: 20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  barChartWrapper: { width: 850, height: 350, marginBottom: 50 },
  pieChartWrapper: { width: 300, height: 280, marginBottom: 80 }
}));

const periodSelector = (style) => {
  return (
    <div style={{ marginLeft: 30, display: "flex", flexDirection: "row", ...style }}>
      <Field
        name={"dateNumber"}
        placeholder={"Number"}
        component={CustomTextField}
        type="number"
        wrapperStyle={{
          marginRight: 30,
          maxWidth: 60,
        }}
        InputProps={{
          inputProps: { 
              max: 1000, min: 1 
          }
      }}
      />
      <Field name={"dateSelector"} component={CustomSelect}>
        <MenuItem value={"day"}>Days</MenuItem>
        <MenuItem value={"week"}>Weeks</MenuItem>
        <MenuItem value={"month"}>Months</MenuItem>
      </Field>
    </div>
  );
};

export default function ChartPanel({
  pieChartData,
  podsChartData,
  flavorChartData,
  history
}) {
  const classes = useStyles();
  const chartData = []
  // filter flavor with v1 ,v2...
  const flavoursWithNA = flavours.concat("N/A");
  _.forEach(flavorChartData, item => {
    const itemData = {}
    _.forEach(flavoursWithNA, flavour => {
      _.forEach(Object.keys(item), key => {
        if (_.includes(key, flavour) || key === "date") {
          if (key === "date") {
            itemData["date"] = item.date
          } else if (itemData[flavour]) {
            itemData[flavour] = itemData[flavour] + item[key]
          } else {
            itemData[flavour] = item[key]
          }
        }
      })
    })
    chartData.push(itemData)
  })

  return (
    <div className={classes.panelRoot}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {"Charts"}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container style={{ alignItems: "flex-start" }}>
            <Grid item xs={3} className={classes.pieChartWrapper}>
              <StatsPie
                data={pieChartData}
                chartColor={["#96E296", "#FFB44F", "#FA7572", "red"]}
                //online, action, inactive
                onClick={props => {
                  history.push(`/analytics?status=${_.get(props, "label")}`);
                }}
              />
            </Grid>
            <Grid item xs={9} className={classes.barChartWrapper}>
              {periodSelector()}
              <StatsChart
                xs={9}
                labelColor={"#000"}
                leftLegend={"units dispensed"}
                data={podsChartData || []}
                colors={["#83CFCC", "#CFEDEC", "#7b8696"]}
                buttonLegend={"date"}
                keys={["Sales UK", "Sales DE", "n/a"]}
                groupMode="stacked"
              />
            </Grid>
            <Grid item xs={12} className={classes.barChartWrapper}>
              <StatsChart
                xs={9}
                labelColor={"#000"}
                data={chartData || []}
                colors={["#7b8696", "#9c755f", "#e6e7df", "#eea4bd", "#eeee5e", "#c8ab77", "#fcf9f0", "#81d1e8"]}
                buttonLegend={"date"}
                leftLegend={"units dispensed"}
                keys={[
                  "N/A",
                  "chocolate",
                  "vanilla",
                  "strawberry",
                  "mango",
                  "caramel",
                  "white",
                  "natural"
                ]}
                groupMode="stacked"
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
