import React from "react";
import {
    Grid,
    Button,
    Typography,
} from "@material-ui/core";
import { CustomTextField } from "components";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { reduxForm, Field, reset } from "redux-form";
import { connect } from "react-redux";
import _ from "lodash";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import {
    fetchComments,
    createComment,
} from "../../../redux/actions/machines";


const FORM_NAME = "comments-form";


const useStyles = makeStyles((theme) => ({
    underlineInput: {
        "&::before": {
            borderBottom: 0,
        },
        "&::after": {
            borderBottom: 0,
        },
        "&:hover": {
            "&::before": {
                borderBottom: "0 !important",
            },
        },
    },
    row: {
        marginBottom: 4,
        padding: 4,
    },
    value: {
        width: "100%",
        color: theme.colors.monteCarlo,
        fontSize: "1.1rem",
    },
    label: {
        width: "100%",
        fontSize: "1.1rem",
    },
    inputRoot2: {
        padding: 10,
    },
    commentWrapper: {
        flexDirection: "column",
        textAlign: "right",

    },
    btn2: {
        width: 100,
        alignSelf: "flex-end",
    },
    commentRow: {
        backgroundColor: theme.colors.monteCarlo,
        color: "white",
        marginBottom: 20,
    },
    content: {
        padding: 20,
        fontWeight: "bolder",
        backgroundColor: theme.colors.monteCarloLight,
    },
    titleComment: {
        padding: 20,
        fontWeight: "bolder",
        fontSize: "1.1rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    commentList: {
        flexDirection: "row",
        maxHeight: 600,
        overflowY: "auto",
    },
    arrowStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function CommentSection(props) {
    const {
        pristine,
        fetchComments,
        machineData,
        comments,
        createComment,
        reset,
    } = props;

    const classes = useStyles();

    const handleSubmit = (e) => {
        e.preventDefault();
        const {
            subject = "",
            content = "",
        } = _.get(props, `formValues.${FORM_NAME}.values`, {});
        if (subject || content) {
            createComment({ subject, content, id: machineData.machineId }).then(
                (res) => {
                    if (res.type === "create-comments-success") {
                        fetchComments(machineData.machineId);
                        reset(FORM_NAME);
                    }
                }
            );
        }
    };

    return (
        <>
            {/* -----------comments---------------- */}
            <Grid container className={classes.row} style={{ marginBottom: 12 }}>
                <Grid item xs={2} className={classes.label}>
                    <FormattedHTMLMessage id="app.detailMachine.title.comments" />
                </Grid>
            </Grid>
            <Grid container className={classes.commentWrapper}>
                <form onSubmit={handleSubmit}>
                    <Field
                        name="subject"
                        placeholder={"Subject"}
                        component={CustomTextField}
                        wrapperStyle={{ marginBottom: 23, border: "1px solid #27b2ad" }}
                        inputClasses={{
                            underline: classes.underlineInput,
                            input: classes.inputRoot2,
                        }}
                    />
                    <Field
                        name="content"
                        rows={3}
                        multiline
                        placeholder={"Enter your comment..."}
                        component={CustomTextField}
                        wrapperStyle={{ marginBottom: 23, border: "1px solid #27b2ad" }}
                        inputClasses={{
                            underline: classes.underlineInput,
                            input: classes.inputRoot2,
                        }}
                    />
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        className={classes.btn2}
                        disabled={pristine}
                    >
                        <Typography variant="button">
                            <FormattedMessage id="app.general.save" />
                        </Typography>
                    </Button>
                </form>
            </Grid>
            {/* -----------list---------------- */}
            {!_.isEmpty(comments) && (
                <Grid container className={classes.row} style={{ marginBottom: 12 }}>
                    <Grid item xs={2} className={classes.label}>
                        <FormattedHTMLMessage id="app.detailMachine.title.list" />
                    </Grid>
                </Grid>
            )}
            <Grid container className={classes.commentList}>
                {_.map(comments, (item) => (
                    <Grid item xs={12} className={classes.commentRow}>
                        <Grid className={classes.titleComment}>
                            <span>{item.subject}</span>

                            <span>
                                {item.user && `commented by ${item.user}, on`}{" "}
                                {moment(item.created_at).format("DD/MM/YYYY, HH:mm")}
                            </span>
                        </Grid>
                        <Grid className={classes.content}> {item.content}</Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default connect(
        (state) => {
            return ({
                formValues: state.form,
                comments: state.machines.comments.data,
                singleMachineData: state.machines.machineData.data,
                loadingSingleMachineData: state.machines.machineData.fetching,
            })
        },
        {
            fetchComments,
            createComment,
            reset,
        }
    )(
        reduxForm({
            form: FORM_NAME
        })(CommentSection)
    )
