import React from "react";
import { CardMedia, IconButton, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import Modal from '@material-ui/core/Modal';
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {
  uploadGalleryComment
} from "../../../redux/actions/machines";
import config from "helpers/config";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, reset } from "redux-form";
import { CustomTextField } from "components";


const useStyles = makeStyles((theme) => ({
  imgGallery: {
    marginRight: 15,
    marginLeft: 15,
    marginTop: 30,
    height: 180,
    width: 264,
    border: `1px solid ${theme.colors.monteCarlo}`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginBottom: 10,
    cursor: "pointer"
  },
  inputRoot: {
    padding: 10
  },
  underlineInput: {
    "&::before": {
      borderBottom: 0,
    },
    "&::after": {
      borderBottom: 0,
    },
    "&:hover": {
      "&::before": {
        borderBottom: "0 !important",
      },
    },
  },
  root: {
    display: "flex",
    flexDirection: "column"
  },
  dateGallery: {
    marginRight: 15,
    marginLeft: 15,
    marginBottom: 10,
  },
  formGallery: {
    marginRight: 15,
    marginLeft: 15,
    display: "flex",
    flexDirection: "column",
  },
  galleryComment: {
    marginRight: 15,
    marginLeft: 15,
    display: "flex",
    flexDirection: "column",
    maxWidth: 264,
    overflowWrap: "break-word",
    backgroundColor: theme.colors.monteCarlo,
    color: "white",
    padding: 10

  },
  btn2: {
    width: 100,
    alignSelf: "flex-end",
  },
  imgCtner: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  img: {
    flex: 1
  }
}));

function GalleryComponent(props) {
  const { imageItem, deleteGalleryFcn, pristine, formName, fetchMachine, reset } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      comment = "",
    } = _.get(props, `formValues.${formName}.values`, {});
    if (comment) {
      uploadGalleryComment(imageItem.id, { comment }).then(
        (res) => {
          if (res.status === 200) {
            fetchMachine()
            reset(formName);
          }
        }
      );
    }
  };
  const galleryComment = _.get(imageItem, "comment")

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={classes.imgCtner}>
          <img
            src={`${config.apiHost}uploads/devices/${imageItem.machine_sn}/gallery/${_.get(
              imageItem,
              "upload_file.fileName"
            )}`}
            style={{ maxWidth: 900 }}
            alt="inter-icon"
          />
        </div>
      </Modal>
      <CardMedia
        image={`${config.apiHost}uploads/devices/${imageItem.machine_sn}/gallery/${_.get(
          imageItem,
          "upload_file.fileName"
        )}`}
        onClick={handleOpen}
        title="image"
        className={classes.imgGallery}
      >
        <IconButton
          aria-label="settings"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation()
            deleteGalleryFcn(imageItem.id)
          }}
        >
          <DeleteIcon style={{ color: "#c0c0c0" }} />
        </IconButton>
      </CardMedia>
      <div className={classes.dateGallery}>
        {`Uploaded: ${moment(_.get(imageItem, "upload_date")).format("DD/MM/YYYY")}`}
      </div>
      {galleryComment ?
        <span className={classes.galleryComment}>{galleryComment}</span> :
        <form className={classes.formGallery} onSubmit={handleSubmit}>
          <Field
            name="comment"
            placeholder={"Enter your comment..."}
            component={CustomTextField}
            wrapperStyle={{ marginBottom: 23, border: "1px solid #27b2ad" }}
            inputClasses={{
              underline: classes.underlineInput,
              input: classes.inputRoot
            }}
          />
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            className={classes.btn2}
            disabled={pristine}
          >
            <Typography variant="button">
              <FormattedMessage id="app.general.save" />
            </Typography>
          </Button>
        </form>
      }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    form: ownProps.formName,
    formValues: state.form,
  };
};

export default compose(
  connect(mapStateToProps, { reset }),
  reduxForm()
)(GalleryComponent);
