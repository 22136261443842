import { Box } from "@material-ui/core";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import ApiClient from "helpers/ApiClient";
import { getPublicMap } from "helpers/apis";
import { transformLocation, parseQuery, round } from "helpers/utilities";
import _ from "lodash";
import React, { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

// import DistanceMatrixService from "./DistanceMatrixService";
import InfoWindow from "./InfoWindow";
import Marker from "./Marker";

const containerStyle = {
  width: "80%",
  height: "80%",
};

const positionDefault = [
  {
    pos: {
      lat: 51.300058,
      lng: -2.533331,
    },
    address: "Farrington Gurney, Bristol BS39 6TQ, UK",
  },
];
const mapOptions = {
  styles: [
    {
      stylers: [
        {
          saturation: -100,
        },
      ],
    },
  ],
  mapId: "b8ca64df1069850c",
  scrollwheel: false,
};

export default function Map(props) {
  const { search } = useLocation();
  const [map, setMap] = React.useState(null);
  const [position, setPosition] = React.useState(positionDefault);
  const [windowOpen, setWindowOpen] = React.useState(false);
  const [windowPosition, setWindowPosition] = React.useState(position[0]?.pos);
  const [windowTitle, setWindowTitle] = React.useState("");
  const [currentPosition, setCurrentPosition] = React.useState(null);
  const [distant, setDistant] = React.useState(null);
  const [positionSelected, setPositionSelected] = React.useState(
    position[0]?.pos
  );
  // const [postcode, setPostcode] = React.useState(null);
  // const [firstLoad, setFirstLoad] = React.useState(true);

  useEffect(() => {
    const getLocations = async () => {
      const { data } = await ApiClient.get(`/customers/publics`);
      const filteredData = _.filter(
        data,
        (item) =>
          !_.isNull(_.get(item, "pos.lat")) || !_.isNull(_.get(item, "pos.lng"))
      );
      if (!_.isEmpty(filteredData)) {
        setPosition(filteredData);
        setPositionSelected(filteredData[0].pos);
        setWindowPosition(filteredData[0].pos);
      }
    };
    getLocations();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);

  // useEffect(() => {
  //   if (_.get(props.match, "params")) {
  //     const postcode = _.get(props.match, "params.postcode");
  //     setPostcode(postcode);
  //   }
  // }, [props.match]);

  // const geocoder = window.google && new window.google.maps.Geocoder();
  // useEffect(() => {
  //   if (geocoder && postcode && firstLoad) {
  //     geocoder.geocode({ address: postcode }, function(results, status) {
  //       if (status === "OK") {
  //         const places = results[0];
  //         const lat = places.geometry.location.lat();
  //         const lng = places.geometry.location.lng();
  //         setFirstLoad(false);
  //         setPositionSelected({ lat, lng });
  //       } else {
  //         console.log(
  //           "Geocode was not successful for the following reason: " + status
  //         );
  //       }
  //     });
  //   }
  // }, [postcode, geocoder, firstLoad]);

  const onLoad = useCallback(
    (posts) => (ggMap) => {
      const google = window.google;
      const bounds = new google.maps.LatLngBounds();
      posts.forEach(({ pos }) => {
        bounds.extend(new google.maps.LatLng(pos.lat, pos.lng));
      });
      ggMap.fitBounds(bounds);
      setMap(ggMap);
    },
    []
  );

  useEffect(() => {
    (async function() {
      const query = parseQuery(search);
      const keys = ["post_code", "city", "region"];
      const address = keys
        .map((key) => query[key])
        .filter(Boolean)
        .join(", ");
      const geocoder = window.google && new window.google.maps.Geocoder();
      if (geocoder) {
        if (address) {
          const data = await geocoder.geocode({ address });
          const [result] = data.results;
          const { geometry } = result;
          const lng = geometry?.location?.lng();
          const lat = geometry?.location?.lat();

          query["lng"] = query[lng] ?? lng;
          query["lat"] = query[lat] ?? lat;
        }

        getPublicMap(query).then((res) => {
          const { data } = res;
          const _positions = data.map(({ location, distance }) =>
            transformLocation(location, distance)
          );
          setPosition(_positions);
          if (map) {
            onLoad(_positions)(map);
          }
        });
      }
    })();
  }, [search, map]);

  // useEffect(() => {
  //   const query = parseQuery(search);
  //   getPublicMap(query).then((res) => {
  //     const { data } = res;
  //     const _positions = data.map(({ location }) =>
  //       transformLocation(location)
  //     );
  //     setPosition(_positions);
  //     if (map) {
  //       onLoad(_positions)(map);
  //     }
  //   });
  // }, [search, map]);

  const onMarkerClick = (info) => {
    setWindowOpen(true);
    setWindowPosition(info.pos);
    setWindowTitle(info);
  };

  const onInfoWindowClose = () => {
    setWindowOpen(false);
  };
  // const distantServiceOptions = {
  //   destinations: [windowPosition],
  //   origins: [currentPosition],
  //   travelMode: "DRIVING",
  //   unitSystem: window.google && window.google.maps.UnitSystem.IMPERIAL,
  // };

  // const directionsCallback = (res) => {
  //   if (res !== null && _.get(res, "rows.0.elements.0.distance.text")) {
  //     const distance = _.get(res, "rows.0.elements.0.distance.text");
  //     const distanceNumber = distance.match(/\d/g);
  //     setDistant(`${distanceNumber.join("")} mile(s)`);
  //   }
  // };

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  useEffect(() => {
    setWindowPosition(position?.[0]?.pos);
  }, [position]);

  return (
    <Box
      display="flex"
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <LoadScript
        googleMapsApiKey="AIzaSyB1FipQzVpwn3s-v2xGHbf7uZ3z_KG5nMc"
        libraries={["places"]}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={positionSelected}
          zoom={15}
          options={mapOptions}
          onLoad={onLoad(position)}
          onUnmount={onUnmount}
        >
          <Marker position={position} onMarkerClick={onMarkerClick} />

          {/* {windowPosition && currentPosition && (
            <DistanceMatrixService
              options={distantServiceOptions}
              callback={directionsCallback}
            />
          )} */}
          {windowOpen && (
            <InfoWindow
              windowPosition={windowPosition}
              onInfoWindowClose={onInfoWindowClose}
              windowTitle={windowTitle}
              distant={round(windowTitle.distance)}
              onClose={() => {
                setWindowOpen(false);
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
    </Box>
  );
}
