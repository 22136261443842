import _ from "lodash"
import {
  SALE_DEVICES_FETCH,
  SALE_DEVICES_FETCH_FINISH,
  SALE_DEVICES_FETCH_ERROR,
  SALE_PODS_FETCH,
  SALE_PODS_FETCH_FINISH,
  SALE_PODS_FETCH_ERROR,
  SALE_AGENTS_FETCH,
  SALE_AGENTS_FETCH_FINISH,
  SALE_AGENTS_FETCH_ERROR,
  SALE_CUSTOMERS_FETCH,
  SALE_CUSTOMERS_FETCH_FINISH,
  SALE_CUSTOMERS_FETCH_ERROR,
  STATUS_DATA_FETCH,
  STATUS_DATA_FETCH_FINISH,
  STATUS_DATA_FETCH_ERROR,
  ALL_DEVICES_FETCH,
  ALL_DEVICES_FETCH_FINISH,
  ALL_DEVICES_FETCH_ERROR,
  ALL_SALE_PODS_FETCH,
  ALL_SALE_PODS_FETCH_FINISH,
  ALL_SALE_PODS_FETCH_ERROR,
  SALE_POD_FLAVOR_FETCH,
  SALE_POD_FLAVOR_FETCH_FINISH,
  SALE_POD_FLAVOR_FETCH_ERROR,
  SALE_TEAM_FETCH,
  SALE_TEAM_FETCH_FINISH,
  SALE_TEAM_FETCH_ERROR,
  TEAM_DATA_FETCH,
  TEAM_DATA_FETCH_FINISH,
  TEAM_DATA_FETCH_ERROR
} from "./ActionTypes";
import ApiClient from "helpers/ApiClient";

export const fetchSaleDevices = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SALE_DEVICES_FETCH
    });
    try {
      params = { ...getState().statistics.deviceChart.param, ...params };
      const { data } = await ApiClient.get("/statistics/sales/devices", { params });

      return dispatch({
        type: SALE_DEVICES_FETCH_FINISH,
        payload: {
          param: {
            ...params,
            userId: params.userId,
          },
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: SALE_DEVICES_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchAllSaleDevices = () => {
  return async (dispatch) => {
    dispatch({
      type: ALL_DEVICES_FETCH
    });
    try {
      const { data } = await ApiClient.get("/statistics/sales/devices");

      return dispatch({
        type: ALL_DEVICES_FETCH_FINISH,
        payload: {
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: ALL_DEVICES_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchAllSalePods = () => {
  return async (dispatch) => {
    dispatch({
      type: ALL_SALE_PODS_FETCH
    });
    try {
      const { data } = await ApiClient.get("/statistics/sales/pods");
      return dispatch({
        type: ALL_SALE_PODS_FETCH_FINISH,
        payload: {
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: ALL_SALE_PODS_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchSalePods = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SALE_PODS_FETCH
    });
    try {
      params = { ...getState().statistics.podChart.param, ...params };
      const { data } = await ApiClient.get("/statistics/sales/pods", { params });

      return dispatch({
        type: SALE_PODS_FETCH_FINISH,
        payload: {
          param: {
            ...params,
            userId: params.userId,
          },
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: SALE_PODS_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchSalePodFlavors = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SALE_POD_FLAVOR_FETCH
    });
    try {
      params = { ...getState().statistics.flavorChart.param, ...params };
      const { data } = await ApiClient.get("/statistics/sales/pods", { params });
      return dispatch({
        type: SALE_POD_FLAVOR_FETCH_FINISH,
        payload: {
          param: {
            ...params,
          },
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: SALE_POD_FLAVOR_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchPodSaleTeams = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SALE_TEAM_FETCH
    });
    try {
      params = { ...getState().statistics.saleTeams.param, ...params };
      const { data } = await ApiClient.get("/statistics/sales/pods", { params });
      return dispatch({
        type: SALE_TEAM_FETCH_FINISH,
        payload: {
          param: {
            ...params,
          },
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: SALE_TEAM_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchSaleAgents = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SALE_AGENTS_FETCH
    });
    try {
      params = { ...getState().statistics.agentList.param, ...params };
      const { data } = await ApiClient.get("/statistics/sales/agents", { params });

      return dispatch({
        type: SALE_AGENTS_FETCH_FINISH,
        payload: {
          param: {
            ...params,
            userId: params.userId,
          },
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: SALE_AGENTS_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchSaleCustomers = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SALE_CUSTOMERS_FETCH
    });
    try {
      params = { ...getState().statistics.customerList.param, ...params };
      const { data } = await ApiClient.get("/statistics/sales/customers", { params });

      return dispatch({
        type: SALE_CUSTOMERS_FETCH_FINISH,
        payload: {
          param: {
            ...params,
            userId: params.userId,
          },
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: SALE_CUSTOMERS_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchStatusData = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: STATUS_DATA_FETCH
    });
    try {
      params = { ...getState().statistics.statusData.param, ...params };
      const { data } = await ApiClient.get("/statistics/sales/statuses", { params });

      return dispatch({
        type: STATUS_DATA_FETCH_FINISH,
        payload: {
          params,
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: STATUS_DATA_FETCH_ERROR,
        payload: error
      });
    }
  };
};

export const fetchSaleTeams = (params = {}) => {
  return async (dispatch) => {
    dispatch({
      type: TEAM_DATA_FETCH
    });
    try {
      const { data } = await ApiClient.get("/statistics/sales/teamSales");
      return dispatch({
        type: TEAM_DATA_FETCH_FINISH,
        payload: {
          params,
          data
        }
      });
    } catch (error) {
      return dispatch({
        type: TEAM_DATA_FETCH_ERROR,
        payload: error
      });
    }
  };
};