import React, {useEffect} from "react";
import _ from "lodash"
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { CustomBox, CustomTextField, CustomSelect } from "components";
import { Link, Input } from '@material-ui/core';
import { Field, reduxForm, change } from "redux-form";
import { FormattedMessage } from "react-intl";
import validate from "./validate";
import { connect } from "react-redux";
import InputAdornment from '@material-ui/core/InputAdornment';
// import MenuItem from "@material-ui/core/MenuItem";
import { injectIntl } from "react-intl";
import { fetchSettings } from "redux/actions/settings"

const FORM_NAME = "setting-form"

const useStyles = makeStyles(theme => ({
  boxHeader: {
    padding: 16
  },
  cardStyle: {
    width: "100%",
    borderWidth: "0 !important"
  },
  root: {
    backgroundColor: "white",
    display: "flex",
    paddingTop: 48,
    paddingRight: 30,
    paddingLeft: 30,
    flexDirection: "column",
    flexWrap: "nowrap",
    border: "1px #c0c0c0 solid",
    maxWidth: 1400,
    margin: "0 auto"
  },
  agent: {
    fontSize: "1.2rem",
    fontWeight: "bolder",
    paddingBottom: 12
  },
  img: {
    height: 120,
    width: 120,
    marginLeft: 30,
    marginBottom: 30
  },
  underlineInput: {
    "&::before": {
      borderBottom: 0
    },
    "&::after": {
      borderBottom: 0
    },
    "&:hover": {
      "&::before": {
        borderBottom: "0 !important"
      }
    }
  },
  inputRoot: {
    backgroundColor: theme.colors.monteCarlo,
    height: 40,
    color: "white",
    padding: 10,
    alignItems: "center",
    display: "flex"
  },
  buttonWrapper: {
    margin: 20,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",

  },
  btn: {
    width: 120
  },
  downIcon: {
    color: "white"
  },
  texFieldRoot: {
    backgroundColor: theme.colors.monteCarlo
  },
  mrgBt20: {
    color: theme.colors.monteCarlo,
    fontSize: 20,
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 250,
    flexDirection: "column",
    marginTop: 30
  },
  linkToOdoo: {
    color: "white",
    padding: 16
  },
  selectWrapper: {
    width: "100%",
    backgroundColor: theme.colors.monteCarlo,
    marginBottom: 23
  },
  submitButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  customTitle:{
    fontSize: "1rem"
  }
}));

function Setting(props) {
  const classes = useStyles();
  const { pristine, intl, fetchSettings, settings } = props

  useEffect(() => {
    fetchSettings()
  }, [])
  return (
    <Grid container className={classes.root}>
      <Grid>
        <Typography className={classes.agent}><FormattedMessage id="app.general.agent" /></Typography>
      </Grid>
      <CustomBox
        boxColor={"#27b2ad"}
        contentColor={"white"}
        title={_.get(settings, "name") || _.get(settings, "email", "")}
        classNameCard={classes.cardStyle}
        classHeaderCard={classes.boxHeader}
        headerClasses={{title:classes.customTitle}}
      >
        <Grid className={classes.avatarWrapper}>
          <CardMedia
            image={"images/User_Icon.png"}
            title="image"
            className={classes.img}
          />
          <Grid className={classes.buttonWrapper}>
            <Button
              variant="contained"
              className={classes.btn}
              type="submit"
            >
              <Typography variant="button"><FormattedMessage id="app.general.change" /></Typography>
            </Button>
            <Button variant="outlined" color="primary" className={classes.btn} disabled={true}>
              <Typography variant="button"><FormattedMessage id="app.general.remove" /></Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.formWrapper}>
          <Typography className={classes.agent}><FormattedMessage id="app.general.email" /></Typography>
          {/* <Field
            name="email"
            component={CustomTextField}
            wrapperStyle={{ marginBottom: 23 }}
            inputClasses={{ underline: classes.underlineInput, input: classes.inputRoot }}
          /> */}
             <CustomTextField
            inputClasses={{ underline: classes.underlineInput, input: classes.inputRoot, root: classes.texFieldRoot }}
            wrapperStyle={{ marginBottom: 23 }}
            disabled={true}
            value={_.get(settings,"email", "")}
          />
          <Typography className={classes.agent}><FormattedMessage id="app.general.password" /></Typography>
          <CustomTextField
            inputClasses={{ underline: classes.underlineInput, input: classes.inputRoot, root: classes.texFieldRoot }}
            wrapperStyle={{ marginBottom: 23 }}
            disabled={true}
            value={intl.formatMessage({
              id: "app.setting.changePassword"
            })}
            InputProps={{
              endAdornment:
                <InputAdornment className={classes.linkToOdoo} position="end">
                  <Link style={{ color: "white" }} href="https://www.odoo.com/" underline='always'>
                    <FormattedMessage id="app.setting.goToOdoo" />
                  </Link>
                </InputAdornment>,
            }}
          />
          <Typography className={classes.agent}><FormattedMessage id="app.setting.salesTeam" /></Typography>
          <CustomTextField
            inputClasses={{ underline: classes.underlineInput, input: classes.inputRoot, root: classes.texFieldRoot }}
            wrapperStyle={{ marginBottom: 23 }}
            disabled={true}
            value={_.get(settings, "saleTeam")}
          />
          {/* <Field
            name={"saleTeam"}
            component={CustomSelect}
            selectWrapper={classes.selectWrapper}
            classes={{ icon: classes.downIcon }}
            customInput={
              <Input
                classes={{
                  underline: classes.underlineInput,
                  input: classes.inputRoot
                }}
              />
            }
          >
            <MenuItem value={"DE"}>Germany</MenuItem>
            <MenuItem value={"GB"}>UK</MenuItem>
          </Field> */}
          <Typography className={classes.agent}><FormattedMessage id="app.setting.language" /></Typography>
          <CustomTextField
            inputClasses={{ underline: classes.underlineInput, input: classes.inputRoot, root: classes.texFieldRoot }}
            wrapperStyle={{ marginBottom: 23 }}
            disabled={true}
            value={intl.formatMessage({
              id: _.get(settings, "settings.language") ? `app.setting.language.${_.get(settings, "settings.language")}`: "n/a"
            })}
          />
          {/* <Field
            name={"language"}
            component={CustomSelect}
            selectWrapper={classes.selectWrapper}
            classes={{ icon: classes.downIcon }}
            customInput={
              <Input
                classes={{
                  underline: classes.underlineInput,
                  input: classes.inputRoot
                }}
              />
            }
          >
            <MenuItem value={"de_DE"}>German</MenuItem>
            <MenuItem value={"en_GB"}>English</MenuItem>
          </Field> */}
          <Grid className={classes.submitButtonWrapper}>
            <Button
              variant="contained"
              className={classes.btn}
              type="submit"
              disabled={pristine}
            >
              <Typography variant="button"><FormattedMessage id="app.general.save" /></Typography>
            </Button>
          </Grid>
        </Grid>
      </CustomBox>
    </Grid>
  );
}

export default injectIntl(connect(
  state => ({
    settings: state.settings.settings.data,
  }),
  dispatch => ({
    fetchSettings: () => dispatch(fetchSettings()),
    changeFieldValue: (field, value) => dispatch(change(FORM_NAME, field, value))
  })
  )
  (reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate
  })(Setting)));
