import { combineReducers } from "redux";
import { intlReducer as intl } from "react-intl-redux";
import { reducer as form } from "redux-form";
import machines from "./machines";
import users from "./users";
import auth from "./auth";
import statistics from "./statistics";
import settings from "./settings";

const myReducer = combineReducers({
  intl,
  form, 
  auth,
  users,
  statistics,
  settings, 
  machines
});

const rootReducer = (state, action) => {
  return myReducer(state, action)
}

export default rootReducer;
