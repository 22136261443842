import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

const PrivateRoute = ({component: Component, tabIndex, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            localStorage.getItem('__fwip_token__')  ?
                <Component {...props} tabIndex={tabIndex}/>
            : <Redirect to="/signin" />
        )} />
    );
};

const PrivateRouteWrapper = connect(
    (state) => ({auth: state.auth}),
    null
)(PrivateRoute);
export default PrivateRouteWrapper
