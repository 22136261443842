import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_TOKEN,
  LOGOUT
} from "./ActionTypes";
import ApiClient from "helpers/ApiClient";

export const login = payload => {
  return async dispatch => {
    dispatch({
      type: LOGIN,
      payload: ""
    });
    try {
      const { data } = await ApiClient.post(`/auth/token`, payload);
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: LOGIN_ERROR,
        payload: error
      });
    }
  };
};


export const refreshAccessToken = refreshToken => {
  return async dispatch => {
    try {

      const { data } = await ApiClient.post("/auth/refresh", {
        refresh_token: refreshToken
      });
      return dispatch({
        type: SET_TOKEN,
        payload: data
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const logOut = () => {
  return dispatch => {
    dispatch({
      type: LOGOUT
    });
  };
};
