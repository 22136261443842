import {
  SALE_DEVICES_FETCH,
  SALE_DEVICES_FETCH_FINISH,
  SALE_DEVICES_FETCH_ERROR,
  SALE_PODS_FETCH,
  SALE_PODS_FETCH_FINISH,
  SALE_PODS_FETCH_ERROR,
  SALE_AGENTS_FETCH,
  SALE_AGENTS_FETCH_FINISH,
  SALE_AGENTS_FETCH_ERROR,
  SALE_CUSTOMERS_FETCH,
  SALE_CUSTOMERS_FETCH_FINISH,
  SALE_CUSTOMERS_FETCH_ERROR,
  STATUS_DATA_FETCH,
  STATUS_DATA_FETCH_FINISH,
  STATUS_DATA_FETCH_ERROR,
  ALL_DEVICES_FETCH,
  ALL_DEVICES_FETCH_FINISH,
  ALL_DEVICES_FETCH_ERROR,
  ALL_SALE_PODS_FETCH,
  ALL_SALE_PODS_FETCH_FINISH,
  ALL_SALE_PODS_FETCH_ERROR,
  SALE_POD_FLAVOR_FETCH,
  SALE_POD_FLAVOR_FETCH_FINISH,
  SALE_POD_FLAVOR_FETCH_ERROR,
  SALE_TEAM_FETCH,
  SALE_TEAM_FETCH_FINISH,
  SALE_TEAM_FETCH_ERROR,
  TEAM_DATA_FETCH,
  TEAM_DATA_FETCH_FINISH,
  TEAM_DATA_FETCH_ERROR
} from "../actions/ActionTypes";

const initialState = {
  deviceChart: {
    param: {},
    fetching: true,
    dataTable: [],
    error: null
  },
  allDeviceData: {
    fetching: true,
    data: [],
    error: null
  },
  allPodData: {
    fetching: true,
    data: [],
    error: null
  },
  podChart: {
    param: {},
    fetching: true,
    dataTable: [],
    error: null
  },
  flavorChart: {
    param: {},
    fetching: true,
    dataTable: [],
    error: null
  },
  agentList: {
    param: {},
    fetching: true,
    dataTable: [],
    error: null
  },
  customerList: {
    param: {},
    fetching: true,
    dataTable: [],
    error: null
  },
  statusData: {
    param: {},
    fetching: true,
    dataTable: [],
    error: null
  },
  saleTeams: {
    param: {},
    fetching: true,
    dataTable: [],
    error: null
  },
  teamData: {
    param: {},
    fetching: true,
    dataTable: [],
    error: null
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SALE_DEVICES_FETCH:
      return {
        ...state,
        deviceChart: {
          ...state.deviceChart,
          fetching: true
        }
      };
    case SALE_DEVICES_FETCH_FINISH:
      return {
        ...state,
        deviceChart: {
          ...state.deviceChart,
          param: action.payload.param,
          fetching: false,
          error: null,
          dataTable: action.payload.data
        }
      };
    case SALE_DEVICES_FETCH_ERROR:
      return {
        ...state,
        deviceChart: {
          ...state.deviceChart,
          fetching: false,
          error: action.payload
        }
      };
    case ALL_DEVICES_FETCH:
      return {
        ...state,
        allDeviceData: {
          ...state.allDeviceData,
          fetching: true
        }
      };
    case ALL_DEVICES_FETCH_FINISH:
      return {
        ...state,
        allDeviceData: {
          ...state.allDeviceData,
          fetching: false,
          error: null,
          data: action.payload.data
        }
      };
    case ALL_DEVICES_FETCH_ERROR:
      return {
        ...state,
        allDeviceData: {
          ...state.allDeviceData,
          fetching: false,
          error: action.payload
        }
      };
    case ALL_SALE_PODS_FETCH:
      return {
        ...state,
        allPodData: {
          ...state.allPodData,
          fetching: true
        }
      };
    case ALL_SALE_PODS_FETCH_FINISH:
      return {
        ...state,
        allPodData: {
          ...state.allPodData,
          fetching: false,
          error: null,
          data: action.payload.data
        }
      };
    case ALL_SALE_PODS_FETCH_ERROR:
      return {
        ...state,
        allPodData: {
          ...state.allPodData,
          fetching: false,
          error: action.payload
        }
      };
    case SALE_PODS_FETCH:
      return {
        ...state,
        podChart: {
          ...state.podChart,
          fetching: true
        }
      };
    case SALE_PODS_FETCH_FINISH:
      return {
        ...state,
        podChart: {
          ...state.podChart,
          param: action.payload.param,
          fetching: false,
          error: null,
          dataTable: action.payload.data
        }
      };
    case SALE_PODS_FETCH_ERROR:
      return {
        ...state,
        podChart: {
          ...state.podChart,
          fetching: false,
          error: action.payload
        }
      };
      case SALE_TEAM_FETCH:
        return {
          ...state,
          saleTeams: {
            ...state.saleTeams,
            fetching: true
          }
        };
      case SALE_TEAM_FETCH_FINISH:
        return {
          ...state,
          saleTeams: {
            ...state.saleTeams,
            param: action.payload.param,
            fetching: false,
            error: null,
            dataTable: action.payload.data
          }
        };
      case SALE_TEAM_FETCH_ERROR:
        return {
          ...state,
          saleTeams: {
            ...state.saleTeams,
            fetching: false,
            error: action.payload
          }
        };  
    case SALE_POD_FLAVOR_FETCH:
      return {
        ...state,
        flavorChart: {
          ...state.flavorChart,
          fetching: true
        }
      };
    case SALE_POD_FLAVOR_FETCH_FINISH:
      return {
        ...state,
        flavorChart: {
          ...state.flavorChart,
          param: action.payload.param,
          fetching: false,
          error: null,
          dataTable: action.payload.data
        }
      };
    case SALE_POD_FLAVOR_FETCH_ERROR:
      return {
        ...state,
        flavorChart: {
          ...state.flavorChart,
          fetching: false,
          error: action.payload
        }
      };
    case SALE_AGENTS_FETCH:
      return {
        ...state,
        agentList: {
          ...state.podChart,
          fetching: true
        }
      };
    case SALE_AGENTS_FETCH_FINISH:
      return {
        ...state,
        agentList: {
          ...state.agentList,
          param: action.payload.param,
          fetching: false,
          error: null,
          dataTable: action.payload.data
        }
      };
    case SALE_AGENTS_FETCH_ERROR:
      return {
        ...state,
        agentList: {
          ...state.agentList,
          fetching: false,
          error: action.payload
        }
      };
    case SALE_CUSTOMERS_FETCH:
      return {
        ...state,
        customerList: {
          ...state.podChart,
          fetching: true
        }
      };
    case SALE_CUSTOMERS_FETCH_FINISH:
      return {
        ...state,
        customerList: {
          ...state.agentList,
          param: action.payload.param,
          fetching: false,
          error: null,
          dataTable: action.payload.data
        }
      };
    case SALE_CUSTOMERS_FETCH_ERROR:
      return {
        ...state,
        customerList: {
          ...state.agentList,
          fetching: false,
          error: action.payload
        }
      };
    case STATUS_DATA_FETCH:
      return {
        ...state,
        statusData: {
          ...state.statusData,
          fetching: true
        }
      };
    case STATUS_DATA_FETCH_FINISH:
      return {
        ...state,
        statusData: {
          ...state.statusData,
          param: action.payload.param,
          fetching: false,
          error: null,
          dataTable: action.payload.data
        }
      };
    case STATUS_DATA_FETCH_ERROR:
      return {
        ...state,
        statusData: {
          ...state.statusData,
          fetching: false,
          error: action.payload
        }
      };
      case TEAM_DATA_FETCH:
        return {
          ...state,
          teamData: {
            ...state.teamData,
            fetching: true
          }
        };
      case TEAM_DATA_FETCH_FINISH:
        return {
          ...state,
          teamData: {
            ...state.teamData,
            fetching: false,
            error: null,
            dataTable: action.payload.data
          }
        };
      case TEAM_DATA_FETCH_ERROR:
        return {
          ...state,
          teamData: {
            ...state.teamData,
            fetching: false,
            error: action.payload
          }
        };
    default:
      return state;
  }
};
