import React, { Component } from "react"
import _ from "lodash"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import s from "./SmallCard.scss"

class SmallCard extends Component {
  render() {
    const { details = {} } = this.props
    return (
      <Card key={details.key} className={s.card} style={{
        borderColor: details.headerBackgroundColor,
        borderWidth: 2,
        borderStyle: "solid",
        height: "100%"
      }}>
        <CardHeader
          style={{
            backgroundColor: details.headerBackgroundColor,
            minHeight: 30,

          }}
          className="card-header"
          title={<div style={{ color: details.textColor, fontSize: "1rem", fontWeight: "normal", }}>{details.title}</div>}
        />
        <CardContent className="card-content" style={{
          maxHeight: details.maxHeight,
          backgroundColor: details.contentBackgroundColor,
          color: details.headerBackgroundColor,
          minHeight: 170
        }}>
          {details.key !== "devices-pods" ? _.map(details.content, (item, index) => {
            return (
              <Grid container key={index}>
                <Grid item xs={6} className="label-column">
                  {item.label}
                </Grid>
                <Grid item xs={6} className="total-column">
                  {item.totalData}
                </Grid>
              </Grid>
            )
          }) : _.map(details.content, (item, index) => {
            return (
              <Grid container key={index} className="devices-wrapper">
                <Grid item xs={12} className="number-row">
                  {item.totalData}
                </Grid>
                <Grid item xs={12} className="label-row">
                  {item.label}
                </Grid>
                <Grid item xs={12} >
                  {"total"}
                </Grid>
              </Grid>
            )
          })
          }
        </CardContent>
      </Card>
    )
  }
}

export default SmallCard
