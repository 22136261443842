import React, { useEffect, useCallback, useRef } from "react";
import {
  Grid,
  Input,
  CardMedia,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import { reduxForm, Field, reset } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";

import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import {
  updateMachine,
  fetchMachine,
  uploadLogo,
  uploadGallery,
  deleteGallery,
  resetMachineData,
} from "../../../redux/actions/machines";
import { useDropzone } from "react-dropzone";
import { CustomSelect, CustomTextField } from "components";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import config from "helpers/config";
import { flavours } from "helpers/const";
import GalleryComponent from "./GalleryComponent";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommentSection from "./CommentSection";

const FORM_NAME = "detail-form";
const NA_text = "n/a";

const values = [
  { field: "serial", label: "Device (S/N)", value: "12312312312" },
  {
    field: "location",
    label: "Current Location",
    value: "Paul´s Cafe, Cardiff, United Kingdom",
  },
  { field: "customer", label: "Customer", value: "Peter´s Cafe Ltd." },
  { field: "customerPhone", label: "Phone number" },
  { field: "agent", label: "Agent", value: "Agent X" },
  { field: "team", label: "Sales Team", value: "Germany" },
  { field: "lastDispenses", label: "Last pod dispensed" },
  { field: "status", label: "Status", value: "online" },
  {
    field: "offlineTime",
    label: <FormattedHTMLMessage id="app.analytic.table.dispensed" />,
    value: 1585132,
  },
  {
    field: "history",
    label: "History",
    value: [
      { customer: "X", used_pods: 20 },
      { customer: "Y", used_pods: 40 },
      { customer: "Z", used_pods: 10 },
    ],
  },
];

const urlToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    display: "flex",
    paddingTop: 48,
    paddingRight: 30,
    paddingLeft: 60,
    paddingBottom: 30,
    flexDirection: "row",
    border: "1px #c0c0c0 solid",
    maxWidth: 1400,
    margin: "0 auto",
  },
  spinner: {
    display: "flex",
    width: "70%",
    margin: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  spinner2: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
    justifyContent: "space-evenly"
  },
  selectWrapper: {
    width: "100%",
    maxWidth: 200,
    border: `1px solid ${theme.colors.monteCarlo}`,
  },
  img: {
    height: 120,
    width: 120,
    border: `1px solid ${theme.colors.monteCarlo}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgPlaceHolder: {
    height: 120,
    width: 120,
    border: `1px solid #c0c0c0`,
    color: "#c0c0c0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  underlineInput: {
    "&::before": {
      borderBottom: 0,
    },
    "&::after": {
      borderBottom: 0,
    },
    "&:hover": {
      "&::before": {
        borderBottom: "0 !important",
      },
    },
  },
  downIcon: {
    color: theme.colors.monteCarlo,
  },
  row: {
    marginBottom: 4,
    padding: 4,
  },
  value: {
    width: "100%",
    color: theme.colors.monteCarlo,
    fontSize: "1.1rem",
  },
  label: {
    width: "100%",
    fontSize: "1.1rem",
  },
  label2: {
    paddingLeft: 20,
    width: "100%",
  },
  inputRoot: {
    padding: 10,
    color: theme.colors.monteCarlo,
  },
  inputRoot2: {
    padding: 10,
  },
  commentWrapper: {
    flexDirection: "column",
    textAlign: "right",
  },
  btn: {
    borderColor: theme.colors.monteCarlo,
    color: theme.colors.monteCarlo,
    borderRadius: 0,
    marginBottom: 10
  },
  btn2: {
    width: 100,
    alignSelf: "flex-end",
  },

  backIcon: {
    color: theme.colors.monteCarlo,
  },
  deleteIcon: {
    color: "red",
  },
  disabledDeleteIcon: {
    display: "none",
    color: "#c0c0c0",
  },
  nullValue: {
    color: "#c0c0c0",
    width: "100%",
    fontSize: "1.1rem",
  },
  placeholderStyle: {
    color: "#c0c0c0",
    padding: 10,
  },
  galleryPlaceHolder: {
    color: "#c0c0c0",
    padding: 10,
    height: 180,
    width: 264,
    border: `1px dotted #c0c0c0`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer",
  },
  imgGallery: {
    marginRight: 30,
    marginTop: 30,
    height: 180,
    width: 264,
    border: `1px solid ${theme.colors.monteCarlo}`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginBottom: 10,
  },
  arrowStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerDispenses: {
    fontSize: "1.1rem",
  },
  separateLine: {
    width: "70%",
    marginBottom: 20,
    marginTop: 20,
    borderBottom: `1px solid ${theme.colors.monteCarlo}`,
  },
  separateLine2: {
    width: "100%",
    marginBottom: 60,
    marginTop: 60,
    marginRight: 100,
    marginLeft: 100,
    borderBottom: `1px solid ${theme.colors.monteCarlo}`,
  },
}));

function DetailPage(props) {
  const {
    pristine,
    onCloseDetail,
    machineData,
    reset,
    updateMachine,
    fetchMachine,
    singleMachineData,
    loadingSingleMachineData,
    resetMachineData,
  } = props;
  const classes = useStyles();

  const doneDelivery = _.filter(
    singleMachineData,
    (item) => item.deliveryStatus === "done"
  );
  const historyData = _.map(doneDelivery, (item2) => ({
    customer: _.get(item2, "customerName"),
    usedPods: _.get(item2, "usedPods"),
    date: _.get(item2, "scheduled_date"),
  }));

  // filter flavor with v1 ,v2...
  const flavoursWithNA = flavours.concat("N/A");
  const usedPodsFlavor = [];
  _.forEach(flavoursWithNA, (flavour) => {
    _.forEach(Object.keys(machineData), (key) => {
      if (_.includes(key, flavour)) {
        const deliveryFlavorDataHistory = [];
        _.map(doneDelivery, (item, index) =>
          deliveryFlavorDataHistory.push(
            _.get(doneDelivery, [index, "flavorCount", flavour], 0)
          )
        );
        usedPodsFlavor.push({
          label: flavour,
          value: machineData[key],
          deliveryFlavorData: deliveryFlavorDataHistory,
        });
      }
    });
  });

  const [imageUrl, setImageUrl] = React.useState(
    `${config.apiHost}uploads/devices/${
    machineData.serial
    }/logo/${_.get(machineData, ["logo", "fileName"])}`
  );

  const [errorLogoUpload, setErrorLogoUpload] = React.useState(null);
  const [errorGalleryUpload, setErrorGalleryUpload] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(0);
  const [galleryLoading, setGalleryLoading] = React.useState(false);
  const [logoLoading, setLogoLoading] = React.useState(false);
  const [dropImage, setImage] = React.useState("");
  const [imagePosition, setPosition] = React.useState({ x: 0.5, y: 0.5 })
  const [imageScale, setImageScale] = React.useState(1)
  const [historyDataLoading, setHistoryDataLoading] = React.useState(false);
  
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevProps = usePrevious({ singleMachineData });
  const showImages = _.orderBy(
    _.get(singleMachineData, ["0", "galleries"], []),
    ["upload_date"],
    ["asc"]
  );

  const onDropGallery = useCallback(async (acceptedFiles) => {
    setGalleryLoading(true);
    const [file] = acceptedFiles;
    const data = new FormData();
    data.append("file", file);
    try {
      const uploadRes = await uploadGallery(machineData.serial, data);
      if (uploadRes.status === 200 && !_.isEmpty(_.get(uploadRes, "data"))) {
        fetchMachine(machineData.serial).then((res) => {
          if (res.type === "fetch-machine-success") {
            setGalleryLoading(false);
          }
        });

        setErrorGalleryUpload(null);
      }
    } catch (err) {
      // TODO
      err && err.message && setErrorGalleryUpload(err.message);
      setGalleryLoading(false);
    }
  }, []);

  const handleDrop = async (acceptedFiles) => {
    const [file] = acceptedFiles;
    // setImage(file);
    const image = new Image();
    image.addEventListener('load', () => {
      // console.log(`${image.width}x${image.height}`)
      // only select images within width/height limits
      if (image.width > 390 & image.height > 390) {
        setImage(file);
        setErrorLogoUpload(null)
      } else {
        setErrorLogoUpload("image must have width and height greater than 390 pixel")
      }

    });
    image.src = URL.createObjectURL(file)
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });
  const galleryProps = useDropzone({ onDrop: onDropGallery });
  const handleSubmit = (e) => {
    e.preventDefault();
    const { positioning = "", language = "", sim = "" } = _.get(
      props,
      `formValues.${FORM_NAME}.values`,
      {}
    );

    const payloadObj = { positioning, language };
    Object.keys(payloadObj).forEach((key) =>
      payloadObj[key] === undefined ||
        payloadObj[key] === "" ||
        payloadObj[key] === null
        ? delete payloadObj[key]
        : {}
    );
    payloadObj.sim = sim;
    updateMachine({
      payload: payloadObj,
      sn: machineData.serial,
    }).then((res) => {
      if (res.type === "update-machine-success") {
        fetchMachine(machineData.serial).then((res2) => {
          if (res2.type === "fetch-machine-success") {
            reset(FORM_NAME);
          }
        });
      }
    });
  };

  const _handleBackBtnClick = () => setSelectedImage(selectedImage - 1);

  const _handleNextBtnClick = () => setSelectedImage(selectedImage + 1);

  const deleteIconFcn = () => {
    setLogoLoading(true)
    updateMachine({
      payload: { deleteIcon: true },
      sn: machineData.serial,
    }).then((res) => {
      if (res.type === "update-machine-success") {
        fetchMachine(machineData.serial).then((res) => {
          if (res.type === "fetch-machine-success") {
            setLogoLoading(false);
          }
        });
      }
    });
  };

  useEffect(() => {
    setHistoryDataLoading(true);
    fetchMachine(machineData.serial).then((res) => {
      if (res.type === "fetch-machine-success") {
        setHistoryDataLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(_.get(singleMachineData, ["0", "logo"])) &&
      prevProps &&
      prevProps.singleMachineData !== singleMachineData
    ) {
      setImageUrl(
        `${config.apiHost}uploads/devices/${
        machineData.serial
        }/logo/${_.get(singleMachineData, ["0", "logo", "fileName"])}`
      );
    }

    if (
      prevProps &&
      _.size(_.get(prevProps.singleMachineData, ["0", "galleries"], [])) !==
      _.size(_.get(singleMachineData, ["0", "galleries"], []))
    ) {
      setSelectedImage(_.size(showImages) - 3 < 0 ? 0 : _.size(showImages) - 3);
    }
  }, [singleMachineData]);

  const deleteGalleryFcn = (id) => {
    setGalleryLoading(true);
    deleteGallery(_.get(singleMachineData, ["0", "serial"]), id).then((res) => {
      if (res.status === 200) {
        fetchMachine(machineData.serial).then((res) => {
          if (res.type === "fetch-machine-success") {
            setGalleryLoading(false);
          }
        });
      }
    });
  };
  const notHaveImage = _.isEmpty(_.get(singleMachineData, ["0", "logo"]));



  const handlePositionChange = position => {
    setPosition(position)
  }

  const handleScale = e => {
    setImageScale(parseFloat(e.target.value))
  }

  const handleSave = async () => {
    setLogoLoading(true)
    const img = editorRef.current.getImageScaledToCanvas().toDataURL();
    const newFile = await urlToFile(img, _.get(dropImage, "name"), _.get(dropImage, "type"));
    const data = new FormData();
    data.append("file", newFile);

    try {
      const uploadRes = await uploadLogo(machineData.serial, data);
      if (uploadRes.status === 200 && !_.isEmpty(_.get(uploadRes, "data"))) {
        fetchMachine(machineData.serial).then((res) => {
          if (res.type === "fetch-machine-success") {
            setLogoLoading(false);
          }
        });
        setErrorLogoUpload(null);
        setImageUrl(
          `${config.apiHost}uploads/devices/${
          machineData.serial
          }/logo/${_.get(uploadRes, ["data", "fileName"])}`
        );
      }
    } catch (err) {
      // TODO
      err && err.message && setErrorLogoUpload(err.message);
    }
  };

  const editorRef = useRef(null);

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid container className={classes.row} style={{ marginBottom: 12 }}>
        <Grid item xs={2} className={classes.label}>
          <IconButton
            style={{ height: 40, width: 40 }}
            onClick={() => {
              resetMachineData();
              onCloseDetail();
            }}
          >
            <ArrowBackIos className={classes.backIcon} />
            {" Back"}
          </IconButton>
        </Grid>
      </Grid>

      {_.map(values, (item, index) => {
        let value;
        switch (item.field) {
          case "lastDispenses":
            value = machineData.lastDispenses || NA_text;
            break;
          case "status":
            value = (
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                {machineData.machineStatus.icon}
                <span style={{ fontSize: "1.1rem", marginRight: 10 }}>
                  {machineData.machineStatus.value}
                </span>
                {machineData.machineStatus.value !== "online" && (
                  <span style={{ fontStyle: "italic", fontSize: "0.9rem" }}>
                    {"offline time: "}
                    {_.get(machineData, "offlineTime.label") || NA_text}
                  </span>
                )}
              </div>
            );
            break;
          case "offlineTime":
            return null;
          case "history":
            value = !_.isEmpty(historyData)
              ? _.map(historyData, (item2) => (
                <Grid container key={`setting-${index}`}>
                  {`${moment(item2.date).format(
                    "DD/MM/YYYY"
                  )} - pods dispensed: ${item2.usedPods} - ${item2.customer}`}
                </Grid>
              ))
              : "n/a";
            break;
          case "agent":
            value = machineData.user;
            break;
          default:
            value = machineData[item.field];
            break;
        }
        return (
          <Grid container key={`setting-${index}`} className={classes.row}>
            <Grid item xs={2} className={classes.label}>
              {item.label}
            </Grid>
            <Grid
              item
              xs={10}
              className={value === 0 ? classes.nullValue : classes.value}
            >
              {value}
            </Grid>
          </Grid>
        );
      })}
      <div className={classes.separateLine} />
      {historyDataLoading ? (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      ) : (
          <>
            <Grid container className={classes.row}>
              <Grid item xs={2} className={classes.label} />
              {_.map(historyData, (item) => (
                <Grid item xs={2} className={classes.headerDispenses}>
                  {`since ${moment(item.date).format("DD/MM/YYYY")}`}
                </Grid>
              ))}
              <Grid item xs={2} className={classes.headerDispenses}>
                {"Lifetime"}
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs={2} className={classes.label}>
                {"Pods dispensed"}
              </Grid>
              {_.map(historyData, (item) => (
                <Grid
                  item
                  xs={2}
                  className={
                    item.usedPods === 0 ? classes.nullValue : classes.value
                  }
                >
                  {item.usedPods}
                </Grid>
              ))}
              <Grid
                item
                xs={2}
                className={
                  machineData.usedPods === 0 ? classes.nullValue : classes.value
                }
              >
                {machineData.usedPods}
              </Grid>
            </Grid>
            {_.map(usedPodsFlavor, (item, id) => (
              <Grid container key={`flavour-${id}`}>
                <Grid item xs={2} className={classes.label2}>
                  {item.label}
                </Grid>
                {!_.isEmpty(historyData) &&
                  _.map(item.deliveryFlavorData, (item2) => (
                    <Grid
                      item
                      xs={2}
                      className={item2 === 0 ? classes.nullValue : classes.value}
                    >
                      {item2}
                    </Grid>
                  ))}
                <Grid
                  item
                  xs={2}
                  className={item.value === 0 ? classes.nullValue : classes.value}
                >
                  {item.value}
                </Grid>
              </Grid>
            ))}
          </>
      )}
        
      {/* -----------configuration---------------- */}
      <Grid container className={classes.row} style={{ marginBottom: 12 }}>
        <Grid item xs={2} className={classes.label}>
          {" "}
          <FormattedHTMLMessage id="app.detailMachine.title.configuration" />
        </Grid>
      </Grid>
      {/* -----------positioning---------------- */}
      <Grid container className={classes.row}>
        <Grid item xs={2} className={classes.label2}>
          {" "}
          <FormattedHTMLMessage id="app.detailMachine.title.positioning" />
        </Grid>
        <Grid item xs={10} className={classes.value}>
          <Field
            name={"positioning"}
            component={CustomSelect}
            customPlaceHolder={"positioning"}
            selectWrapper={classes.selectWrapper}
            classes={{ icon: classes.downIcon }}
            placeholderStyle={{
              underline: classes.underlineInput,
              input: classes.placeholderStyle,
            }}
            customInput={
              <Input
                classes={{
                  underline: classes.underlineInput,
                  input: classes.inputRoot,
                }}
              />
            }
          >
            <MenuItem value={"frontcounter"}>{"Front Counter"}</MenuItem>
            <MenuItem value={"backcounter"}>{"Back Counter"}</MenuItem>
          </Field>
        </Grid>
      </Grid>
      {/* -----------language---------------- */}
      <Grid container className={classes.row}>
        <Grid item xs={2} className={classes.label2}>
          {" "}
          <FormattedHTMLMessage id="app.detailMachine.title.language" />
        </Grid>
        <Grid item xs={10} className={classes.value}>
          <Field
            name={"language"}
            component={CustomSelect}
            selectWrapper={classes.selectWrapper}
            classes={{ icon: classes.downIcon }}
            customPlaceHolder={"language"}
            placeholderStyle={{
              underline: classes.underlineInput,
              input: classes.placeholderStyle,
            }}
            customInput={
              <Input
                classes={{
                  underline: classes.underlineInput,
                  input: classes.inputRoot,
                }}
              />
            }
          >
            <MenuItem value={"EN"}>{"English"}</MenuItem>
            <MenuItem value={"DE"}>{"German"}</MenuItem>
          </Field>
        </Grid>
      </Grid>
      {/* -----------sim---------------- */}
      <Grid container className={classes.row}>
        <Grid item xs={2} className={classes.label2}>
          {" "}
          <FormattedHTMLMessage id="app.detailMachine.title.sim" />
        </Grid>
        <Grid item xs={10} className={classes.value}>
          <Field
            name={"sim"}
            placeholder={"SIM"}
            component={CustomTextField}
            wrapperStyle={{
              marginBottom: 23,
              borderBottom: "1px solid #27b2ad",
              maxWidth: 200,
            }}
            inputClasses={{
              underline: classes.underlineInput,
              input: classes.inputRoot2,
            }}
          />
        </Grid>
      </Grid>

      {/* -----------logo---------------- */}
      <Grid container className={classes.row}>
        <Grid item xs={2} className={classes.label2}>
          {" "}
          <FormattedHTMLMessage id="app.detailMachine.title.logo" />
        </Grid>
        <Grid item xs={10} className={classes.value}>
          <Grid container>
            <input {...getInputProps()} multiple={false} />
            {!notHaveImage ? (
              <>
                <CardMedia
                  image={imageUrl}
                  title="image"
                  className={classes.img}
                >
                 
                  {logoLoading &&  <CircularProgress />}
                </CardMedia>
              </>
            ) : (
                <Grid container style={{ flexDirection: "column", maxWidth: 290 }}>
                  <Button
                    {...getRootProps()}
                    className={classes.btn}
                    color="primary"
                    variant="outlined"
                  >
                    <Typography variant="button">{"Choose Image"}</Typography>
                  </Button>
                  {!_.isEmpty(dropImage) && <><Dropzone
                    onDrop={handleDrop}
                    noClick
                    noKeyboard
                    style={{ width: "290px", height: "290px" }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <AvatarEditor
                          ref={editorRef}
                          scale={parseFloat(imageScale)}
                          crossOrigin="anonymous"
                          position={imagePosition}
                          onPositionChange={handlePositionChange}
                          width={390}
                          height={390}
                          image={dropImage}
                        />
                        <input {...getInputProps()} />
                      </div>
                    )}
                  </Dropzone>
                    Zoom:
                    <input
                      name="scale"
                      type="range"
                      onChange={handleScale}
                      min={'1'}
                      max="2"
                      step="0.01"
                      defaultValue="1"
                    />
                    <div className={classes.spinner2}>
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.btn}
                        onClick={handleSave}
                      >
                        <Typography variant="button">{"Crop and Save"}</Typography>
                      </Button>
                      {logoLoading && <CircularProgress />}
                    </div>
                  </>
                  }
                </Grid>
              )}
            <IconButton
              style={{ height: 40, width: 40 }}
              onClick={deleteIconFcn}
              disabled={notHaveImage}
            >
              <DeleteIcon
                className={
                  notHaveImage ? classes.disabledDeleteIcon : classes.deleteIcon
                }
              />
            </IconButton>
          </Grid>
          {errorLogoUpload && (
            <div style={{ color: "red" }}>{errorLogoUpload}</div>
          )}
        </Grid>
      </Grid>
      {/* -----------log-file---------------- */}
      <Grid container className={classes.row}>
        <Grid item xs={2} className={classes.label2}>
          {" "}
          <FormattedHTMLMessage id="app.detailMachine.title.logFiles" />
        </Grid>
        <Grid item xs={10} className={classes.value}>
          <Button variant="outlined" color="primary" className={classes.btn}>
            <Typography variant="button">
              <FormattedMessage id="app.login.buttonLabel.request" />
            </Typography>
          </Button>
        </Grid>
      </Grid>
      {/* -----------gallery---------------- */}
      {_.size(showImages) < 5 && (
        <Grid container className={classes.row}>
          <Grid item xs={2} className={classes.label2}>
            {" "}
            <FormattedHTMLMessage id="app.detailMachine.title.gallery" />
          </Grid>
          <Grid item xs={10} className={classes.value}>
            <Grid container>
              <input {...galleryProps.getInputProps()} multiple={true} />
              <div
                {...galleryProps.getRootProps()}
                className={classes.galleryPlaceHolder}
              >
                {galleryLoading ? (
                  <div className={classes.spinner}>
                    <CircularProgress />
                  </div>
                ) : (
                    <FormattedHTMLMessage id="app.detailMachine.title.galleryPlaceHolder" />
                  )}
              </div>
            </Grid>
            {errorGalleryUpload && (
              <div style={{ color: "red" }}>{errorGalleryUpload}</div>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container className={classes.row}>
        <Grid item xs={2} />
        <Grid item xs={10} style={{ display: "flex", marginLeft: -80 }}>
          {!_.isEmpty(showImages) && (
            <>
              <span className={classes.arrowStyle}>
                <IconButton
                  disabled={selectedImage === 0}
                  onClick={selectedImage > 0 ? _handleBackBtnClick : _.noop}
                >
                  <KeyboardArrowLeftIcon style={{ fontSize: "4rem" }} />
                </IconButton>
              </span>
              {_.map(
                _.slice(showImages, selectedImage, selectedImage + 3),
                (item, index) => (
                  <GalleryComponent
                    imageItem={item}
                    deleteGalleryFcn={deleteGalleryFcn}
                    classes={classes}
                    formName={`gallery-${index}`}
                    fetchMachine={() => fetchMachine(machineData.serial)}
                  />
                )
              )}
              <span className={classes.arrowStyle}>
                <IconButton
                  disabled={selectedImage + 3 >= _.size(showImages)}
                  onClick={
                    selectedImage + 3 < _.size(showImages)
                      ? _handleNextBtnClick
                      : _.noop
                  }
                >
                  <KeyboardArrowRightIcon style={{ fontSize: "4rem" }} />
                </IconButton>
              </span>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container className={classes.commentWrapper}>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          className={classes.btn2}
          disabled={pristine}
          onClick={handleSubmit}
        >
          <Typography variant="button">
            <FormattedMessage id="app.general.save" />
          </Typography>
        </Button>
      </Grid>
      <div className={classes.separateLine2} />
      <CommentSection machineData={machineData} />
      <Grid container className={classes.row} style={{ marginBottom: 12 }}>
        <Grid item xs={2} className={classes.label}>
          <IconButton
            style={{ height: 40, width: 40 }}
            onClick={() => {
              resetMachineData();
              onCloseDetail();
            }}
          >
            <ArrowBackIos className={classes.backIcon} />
            {" Back"}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default injectIntl(
  connect(
    (state, props) => ({
      formValues: state.form,
      initialValues: {
        positioning:
          _.get(state.machines.machineData.data, ["0", "positioning"]) ||
          _.get(props, "machineData.positioning"),
        language:
          _.get(state.machines.machineData.data, ["0", "language"]) ||
          _.get(props, "machineData.language"),
        sim:
          _.get(state.machines.machineData.data, ["0", "sim"]) ||
          _.get(props, "machineData.sim"),
      },
      singleMachineData: state.machines.machineData.data,
      loadingSingleMachineData: state.machines.machineData.fetching,
    }),
    {
      reset,
      updateMachine,
      fetchMachine,
      uploadLogo,
      resetMachineData,
    }
  )(
    reduxForm({
      form: FORM_NAME,
      enableReinitialize: true,
    })(DetailPage)
  )
);
