
import React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
} from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    marginBottom: 40
  },
  cardHeader: {
    padding: 4,
    color: "white"

  }
}));

function CustomBox({ boxColor, title, children, contentColor = "white", classNameContent, classNameCard,classHeaderCard, headerClasses }) {
  const classes = useStyles();

  return (
    <Card className={classnames(classes.card,classNameCard)} style={{
      borderColor: boxColor,
      borderWidth: 2,
      borderStyle: "solid"
    }}>
      <CardHeader
        style={{
          backgroundColor: boxColor,
        }}
        classes={headerClasses}
        className={classnames(classes.cardHeader,classHeaderCard)}
        title={title}
      />
      <CardContent style={{
        backgroundColor: contentColor
      }}
      className={classNameContent}
      >
        {children}
      </CardContent>
    </Card>
  )
}


export default CustomBox
