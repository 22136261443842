export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOGIN_SUCCESS = "login-success";
export const LOGIN_ERROR = "login-error";
export const SET_TOKEN = "set-token";
export const MACHINES_FETCH = "fetch-machines";
export const MACHINES_FETCH_FINISH = "fetch-machines-success";
export const MACHINES_FETCH_ERROR = "fetch-machines-error";
export const MACHINE_FETCH = "fetch-machine";
export const MACHINE_FETCH_FINISH = "fetch-machine-success";
export const MACHINE_FETCH_ERROR = "fetch-machine-error";
export const USERS_FETCH = "fetch-users";
export const USERS_FETCH_FINISH = "fetch-users-success";
export const USERS_FETCH_ERROR = "fetch-users-error";
export const SALE_DEVICES_FETCH = "fetch-sale-devices";
export const SALE_DEVICES_FETCH_FINISH = "fetch-sale-devices-success";
export const SALE_DEVICES_FETCH_ERROR = "fetch-sale-devices-error";
export const SALE_PODS_FETCH = "fetch-sale-pods";
export const SALE_PODS_FETCH_FINISH = "fetch-sale-pods-success";
export const SALE_PODS_FETCH_ERROR = "fetch-sale-pods-error";
export const SALE_AGENTS_FETCH = "fetch-sale-agents";
export const SALE_AGENTS_FETCH_FINISH = "fetch-sale-agents-success";
export const SALE_AGENTS_FETCH_ERROR = "fetch-sale-agents-error";
export const SALE_CUSTOMERS_FETCH = "fetch-sale-customers";
export const SALE_CUSTOMERS_FETCH_FINISH = "fetch-sale-customers-success";
export const SALE_CUSTOMERS_FETCH_ERROR = "fetch-sale-customers-error";
export const STATUS_DATA_FETCH = "fetch-status";
export const STATUS_DATA_FETCH_FINISH = "fetch-status-success";
export const STATUS_DATA_FETCH_ERROR = "fetch-status-error";
export const ALL_DEVICES_FETCH = "fetch-all-devices";
export const ALL_DEVICES_FETCH_FINISH = "fetch-all-devices-success";
export const ALL_DEVICES_FETCH_ERROR = "fetch-all-devices-error";
export const ALL_SALE_PODS_FETCH = "fetch-all-pods";
export const ALL_SALE_PODS_FETCH_FINISH = "fetch-all-pods-success";
export const ALL_SALE_PODS_FETCH_ERROR = "fetch-all-pods-error";
export const SALE_POD_FLAVOR_FETCH = "fetch-all-pod-flavor"
export const SALE_POD_FLAVOR_FETCH_FINISH = "fetch-all-pod-success"
export const SALE_POD_FLAVOR_FETCH_ERROR = "fetch-all-pod-error"
export const SALE_TEAM_FETCH = "fetch-sale-teams"
export const SALE_TEAM_FETCH_FINISH = "fetch-sale-teams-success"
export const SALE_TEAM_FETCH_ERROR = "fetch-sale-teams-error"
export const COMMENTS_FETCH = "fetch-comments"
export const COMMENTS_FETCH_FINISH = "fetch-comments-success"
export const COMMENTS_FETCH_ERROR = "fetch-comments-error"
export const CREATE_COMMENT = "create-comments"
export const CREATE_COMMENT_SUCCESS = "create-comments-success"
export const CREATE_COMMENT_ERROR = "create-comments-error"
export const MACHINE_UPDATE = "update-machine"
export const MACHINE_UPDATE_SUCCESS = "update-machine-success"
export const MACHINE_UPDATE_ERROR = "update-machines-error"
export const SETTINGS_FETCH = "fetch-settings"
export const SETTINGS_FETCH_FINISH = "fetch-settings-success"
export const SETTINGS_FETCH_ERROR = "fetch-settings-error"
export const TEAM_DATA_FETCH = "fetch-teams"
export const TEAM_DATA_FETCH_FINISH = "fetch-teams-success"
export const TEAM_DATA_FETCH_ERROR = "fetch-teams-error"
export const RESET_MACHINE_DATA = "reset-machine-data"