
import { EMAIL_VALIDATION_REGEX } from "helpers/utilities"

export default values => {
  const errors = {};
  if (!values.email) {
    errors.email = "app.general.requiredField";
  }

  if (values.email && !EMAIL_VALIDATION_REGEX.test(values.email)) {
    errors.email = "app.validate.email";
  }

  if (!values.password) {
    errors.password = "app.general.requiredField";
  }
  return errors;
};
