import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import _ from "lodash";
import myReducer from "./reducers";

import enUS from "../locale/en.json";

const locale = "en-US";
const messages = {
  "en-US": enUS
};

const initialState = {
  intl: {
    locale,
    messages: _.extend({}, messages[locale])
  }
};

const finalCreateStore = compose(
  applyMiddleware(thunk),
  d =>
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()(d)
      : d
)(createStore);

export default finalCreateStore(myReducer, { ...initialState });
