import {
  USERS_FETCH,
  USERS_FETCH_FINISH,
  USERS_FETCH_ERROR,
} from "../actions/ActionTypes";

const initialState = {
  list: {
    param: {},
    fetching: true,
    dataTable: [],
    error: null
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case USERS_FETCH:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: true
        }
      };
    case USERS_FETCH_FINISH:
      return {
        ...state,
        list: {
          ...state.list,
          param: action.payload.param,
          fetching: false,
          error: null,
          dataTable: action.payload.data
        }
      };
    case USERS_FETCH_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
};
